<template>
    <!--Begin: Shortcuts Version -->
    <div id="layout-config" :class="containerClass">
		<a href="javascript:;" class="layout-config-button bg-primary" id="layout-config-button" @click="toggleVersion"  v-tooltip.right="'Revisión'" >
			<i class="pi pi-tag"></i>
		</a>
        <Sidebar v-model:visible="visibleVersion" position="right" @hide="visibleVersion = false" class="layout-config-content">
            <h5 class="text-primary">Historial de Revisión</h5>
            <RevData   :Service="revisionPlanService" :PlanCode="PlanDidactico.pld_codigo" Mode="print"  />
        </Sidebar>
    </div>
    <!--Begin: Shortcuts Version -->

</template>

<script>

import RevData from "@/components/LaborDocente/Revision/RevData"
import RevisionPlanService from "@/service/revisionPlanService"

export default{
    props:{
        PlanDidactico:{
            type:  Object,
            default(){
                return {}
            }
        }
    },
    emits:[],
    revisionPlanService : null,
    data(){
        
        return {
            visibleVersion : false
        }
    },
    created(){
        this.revisionPlanService =  new RevisionPlanService(this.axios);
    }, 
    mounted(){

    },
    methods:{
        toggleVersion(event) {
            this.visibleVersion = !this.visibleVersion;
            event.preventDefault();
        },
    },
    computed:{
        containerClass() {
            return ['layout-config', {'layout-config-active': this.visibleVersion}];
        },
    },
    components:{
        RevData
    }
}
</script>
