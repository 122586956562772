<template>
    <!--End Section Card -->
    <div class="col-12  lg:col-5" >
        <h5 class="font-semibold text-lg" ><AngleDoublesvg />{{itemGroup[0].fin_tipo_fuente}}</h5>
        
        <div v-for="(fuente,index) in itemGroup" :key="index" class="flex flex-column my-0">
            <div class="mb-1 flex justify-content-between align-items-center">
                <div class="flex flex-columns mx-2" >
                    <div class="mx-2">
                        <div class="symbol symbol-40 symbol-circle symbol-light-primary">
                            <span class="symbol-label ">{{fuente.fin_nombre[0].toUpperCase()}}</span>
                        </div>
                    </div>
                    <div class="flex-grow-1 mb-1">
                        <a href="javascript:void(0)" class="text-gray-900 font-medium  hover:text-primary text-lg">{{fuente.fin_nombre}}</a>
                        <span class="text-gray-500 font-normal block text"> {{fuente.fin_enlace}}</span>
                    </div>
                </div>
                <a href="javascript:void(0)" class="align-items-end btn btn-bg-light-danger p-2 mr-2" v-tooltip.top="'Eliminar'" :key="index"  @click="delete_onClick(fuente)" > 
                    <i class="pi pi-trash text-danger"></i>
                </a>
            </div>
            <Divider type="dashed" class="p-divider-muted"/>
        </div>
    </div>
    <!--End : Section  -->
    <div class="col-1">
        <Divider layout="vertical"  class="hidden md:inline-flex"></Divider>
    </div>
</template>


<script>
import AngleDoublesvg from '../svg/AngleDoublesvg';
export default {
  components: { AngleDoublesvg },
    name:  "SectionCardGroup",
    emits : ["delete_onClick"],
    props : {
        itemGroup: {
            type : Object,
            default(){ 
                return {}
            }
            
        }
    },
    data(){
        return {

        }
    },
    methods:{
        async delete_onClick(data){
            this.$emit("delete_onClick",data);
        }
    },

}
</script>




<style scoped>
.text{
    word-break: break-all;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
}
</style>