<template>
    <form @submit.prevent="handleSubmit(!v$.$invalid)" >
        <div class="p-fluid p-2">
            <div class="grid">
                <div class="col-12 md:col-4">
                    <div class="field">
                        <label for="pbd_fecha_desde" >Fecha Desde</label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-calendar"></i>
                            </span>
                            <Calendar id="pbd_fecha_desde" 
                                v-model="eBloqueDetalle.pbd_fecha_desde" 
                                dateFormat="dd/mm/yy"/>
                        </div>
                        
                    </div>
                </div>
                <div class="col-12 md:col-4">
                    <div class="field">
                        <label for="pbd_fecha_hasta" >Fecha Hasta</label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-calendar"></i>
                            </span>
                            <Calendar id="pbd_fecha_desde" v-model="eBloqueDetalle.pbd_fecha_hasta"  dateFormat="dd/mm/yy"/>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-3">
                    <div class="field">
                        <label for="pbd_semana" >Ciclo Semana</label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-calendar-plus"></i>
                            </span>
                            <InputText for="pbd_semana" v-model="eBloqueDetalle.pbd_semana"   class="text-right"/>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="field-checkbox">
                        <Checkbox id="binary" v-model="eBloqueDetalle.pbd_niveles" :binary="true" />
                        <label for="binary">Nivel de Dominio. <i>Marcar si es última semana del bloque</i></label>
                    </div>
                </div>
            </div>

            <Divider type="dashed"  class="p-divider-danger"/>
            <div class="flex flex-column justify-content-center my-4">
                <h5  class="font-semibold text-lg pb-0 mb-0 "><AngleDoublesvg/>Ejes</h5>
                <span class="text-sm font-semibold text-gray-500 ml-3">Bloques de programación semanal. Marque solo las que aplicará a la programación</span>   
            </div>
            <div class="grid p-fluid ml-3" :style="eBloqueDetalle.pbd_niveles == true ? ' pointer-events: none; opacity: 0.4;' : ''">
                <div class="col-12 md:col-6 xl:col-6"  v-for="eje of ejeList" :key="eje.epl_codigo" >
                    <div class="field-checkbox">
                        <Checkbox :id="eje.epl_codigo" name="eje_selected" :value="eje" v-model="selectedEjePlan" :disabled="isExists(eje)"/>
                        <label :for="eje.key">{{eje.epl_nombre}}</label>
                    </div>
                </div>
                <div class="col-12">
                    <small class="p-error" v-if="submitted && selectedEjePlan.length==0">* Seleccione al menos 1 Eje.</small>
                </div>
            </div>
        </div>
         <div class="flex justify-content-end">
            <Button label="Cancelar" icon="pi pi-times" class="p-button-danger p-button-text mx-2" @click="cancel_onClick()"/>
            <Button type="submit" label="Guardar" class="p-mt-2" />
         </div>
    </form>
</template>

<script>

//import { required,helpers,minLength  } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import AngleDoublesvg from '../../svg/AngleDoublesvg';
import moment from 'moment';

export default {
  components: { AngleDoublesvg },
    
    setup: () => ({ v$: useVuelidate() }),
    emits : ["save_onEmit","cancel_onEmit"],
    props:{
        BloqueDetalle :{
            type: Object,
            default(){
                return {}
            }
        },
        Ejes : {
            type: Array,
            default(){
                return []
            }
        },
        service: null
    },
    data(){
        return{
            eBloqueDetalle : { ... this.BloqueDetalle },
            selectedEjePlan: this.Ejes,
            ejeList: [],
            submitted : false,
        }
    },

    async created(){
        await this.service.getEjes().then(res => this.ejeList = res)
        if(this.eBloqueDetalle.pbd_codigo === 0){ //Default Value
           this.selectedEjePlan = this.ejeList
       }
    },
   
    async mounted(){
    
    },
    methods: {
        setTouched(model) {
            console.log(model)
        },
        async fx_Save(){
            try{
                this.eBloqueDetalle.ejePlanificacion = this.selectedEjePlan //asignando ejes al bloque detalle
                await this.service.createBloqueDetalle(this.eBloqueDetalle.pbd_codplb, this.eBloqueDetalle).then(res => this.eBloqueDetalle = res);
                this.$emit("save_onEmit",this.eBloqueDetalle);
            }
            catch(ex){
                if(ex.response.data){
                    this.$toast.add({severity:'error', summary:'Cancelado', detail:ex.response.data, life: 3000});  
                }
                this.$catchError(ex);
            }
        },

        async handleSubmit(isFormValid){
           
                this.submitted = true;
                var semaNumber = Number(this.eBloqueDetalle.pbd_semana)

                const startDate =   moment(this.eBloqueDetalle.pbd_fecha_desde).format("YYYY/MM/DD")
                const endDate =   moment(this.eBloqueDetalle.pbd_fecha_hasta).format("YYYY/MM/DD")

                if(endDate <= startDate){
                    this.$toast.add({severity:'error', 
                        summary:'Fecha inicio', detail: "Inicio es mayor que fecha fin.", life: 3000});
                     return
                }

                if(typeof semaNumber == "string") {
                     this.$toast.add({severity:'error', summary:'Número de Semana', detail: "Semana no tiene formato correcto.", life: 3000});
                     return
                }
                if(semaNumber == 0){
                     this.$toast.add({severity:'error', summary:'Número de Semana', detail: "Semana debe ser mayor a cero.", life: 3000});
                     return
                }


                if (!isFormValid || (this.selectedEjePlan.length == 0 && !this.eBloqueDetalle.pbd_niveles)) {
                    return; // Validacion
                }

                if(this.eBloqueDetalle.pbd_niveles != this.BloqueDetalle.pbd_niveles && this.BloqueDetalle.pbd_codigo != 0)
                {
                    //Cambiando el tipo de semana.
                    this.$confirm.require({
                        message: '¿Está seguro de cambiar el tipo de esta semana? Los datos ingresados anteriormente podrían perderse.',
                        header: 'Confirmación',
                        icon: 'pi pi-exclamation-triangle text-yellow-600',
                        acceptLabel : "Sí, actualizar",
                        acceptClass : "p-button-danger",
                        accept: async() => {
                            await this.fx_Save();
                        }
                    })
                }
                else{
                    await this.fx_Save();
                }
        },

        cancel_onClick(){
            this.$emit("cancel_onEmit");
        },
        
        isExists(item){
            var x =  this.Ejes.findIndex(x=> x.epl_codigo === item.epl_codigo )
            return x >= 0;
        }
    },
}
</script>