<template>
    <div class="mb-1">
        <div class="flex align-items-center border-round p-4 mb-3" v-for="rev in revisionList" :key="rev" :class="estadoColorClass(rev.estado)">
            <span class="mr-2"><i :class="estadoIcon(rev.estado)"/></span>
            <div class="flex flex-column flex-grow-1 mr-2">
                <a href="javascript:;" class="font-normal text-gray-700 hover:text-primary text-lg mb-1">Revisión No. {{rev.rev_num}}</a>
                <span class="text-muted text-sm">Presenta {{fx_DateFormat(rev.fecha_presentacion)}}</span>
                <span class="text-muted text-sm">Revisión {{fx_DateFormat(rev.fecha_revision)}}</span>
            </div>
            <a href="javascript:;" class="btn btn-bg-light p-2 mr-2 text-primary hover:text-primary"  v-tooltip.top="'Imprimir'"  
                @click="print_onClick(rev)"  v-if="Mode =='edit' || Mode == 'print'"> 
                <i class="pi pi-print"></i> 
            </a>
        </div>
    </div>
    <RevisionPrint :Service="Service"  v-model:Visible="isPrintDialog"  :Value="revisionSelected"  v-if="isPrintDialog"/>
</template>

<script>

import moment from 'moment'
import RevisionPrint from "@/components/LaborDocente/Revision/RevisionPrintDialog"

export default {
    props:{
        Service: null,
        PlanCode: {
            type: Number,
            default: 0
        },
        Mode:{
            type: String,
            default: "edit"
        }
    },
    emits:["print_onEmit"],
    data(){
        return {
            revisionList : [],
            revisionSelected: {},
            isPrintDialog : false
        }
    },
    created(){

    }, 
    async mounted(){
        await this.Service.getAllRevision(this.PlanCode)
            .then(res => this.revisionList =  res)
    },
    methods:{
        print_onClick(rev){
            this.revisionSelected =  rev
            this.isPrintDialog = true
        },
        toggleVersion(event) {
            this.visibleVersion = !this.visibleVersion;
            event.preventDefault();
        },

        fx_DateFormat(data) {
            var startDate = new Date(2021, 1, 1)
            var dateData = new Date(data);
            if(dateData < startDate)
                return "-"
            return moment(data).format('DD/MM/YYYY')
        },

        estadoColorClass(status){
            var controlBase =  'bg-light-'
            if(status === 'S')
                controlBase += 'primary'
            else if(status === 'N' )
                controlBase += 'danger'
            else
                controlBase += 'warning'
            
            return controlBase;
        },
        estadoIcon(status){
            var iconBase =  'pi  '
           
            if(status === 'S')
                iconBase += 'pi-check text-primary font-bold'
            else if(status === 'N' )
                iconBase += 'pi-times text-danger  font-bold'
            else
                iconBase += 'pi-pencil text-warning font-bold'
            
            return iconBase;
        },
        
    },
    computed:{

    },
    components:{
        RevisionPrint
    }
}
</script>