<template>
     <form @submit.prevent="handleSubmit(!v$.$invalid)" >
        <div class="p-fluid">
            <div class="formgird grid">
                <div class="field col-6 md:col-3">
                    <label for="pla_horas_ens" :class="{'p-error':v$.horas_ens.$invalid && submitted}" >Horas Enseñanza</label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-clock text-primary"></i>
                        </span>
                        <InputText for="pla_horas_ens" v-model="horas_ens"  :class="{'p-invalid':v$.horas_ens.$invalid && submitted}" class="text-right"/>
                    </div>
                </div>
                <div class="field col-6  md:col-3">
                    <label for="pla_horas_apren" :class="{'p-error':v$.horas_apren.$invalid && submitted}" >Horas Aprendizaje</label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-clock text-primary"></i>
                        </span>
                        <InputText for="pla_horas_apren" v-model="horas_apren" :class="{'p-invalid':v$.horas_apren.$invalid && submitted}" class="text-right"/>
                    </div>
                </div>
            </div>
            <div class="field">
                <label for="pld_com_asignatura" :class="{'p-error':v$.pld_com_asignatura.$invalid && submitted}">Competencia específica de la asignatura</label>
                <Textarea for="pld_com_asignatura" v-model="pld_com_asignatura" :autoResize="true" rows="3" cols="30" :class="{'p-invalid':v$.pld_com_asignatura.$invalid && submitted}"/>
                <div class="flex justify-content-between" :class="{'text-danger': (pld_com_asignatura.length > 2500)}">
                    <small class="form-text text-muted">Estructura: Verbo en infinitivo + objeto conceptual + finalidad + condición de referencia y forma de apropiación.</small>
                    <small>{{pld_com_asignatura.length}}/2500</small>
                </div>
                <small v-if="(v$.pld_com_asignatura.minLength.$invalid && submitted) || v$.pld_com_asignatura.$pending.$response" class="p-error">{{v$.pld_com_asignatura.minLength.$message}}</small>
                <small v-if="(v$.pld_com_asignatura.required.$invalid && submitted) || v$.pld_com_asignatura.$pending.$response" class="p-error">{{v$.pld_com_asignatura.required.$message}}</small>
            </div>
            <Divider type="dashed" class="p-divider-muted"/>
            <div class="field">
                <label for="pld_problematica" :class="{'p-error':v$.pld_problematica.$invalid && submitted}">Situación Problemática</label>
                <Textarea for="pld_problematica" v-model="pld_problematica" :autoResize="true" rows="3" cols="30" :class="{'p-invalid':v$.pld_problematica.$invalid && submitted}"/>
                <div class="flex justify-content-end" :class="{'text-danger': (pld_problematica.length > 2500)}">
                    
                    <small>{{pld_problematica.length}}/2500</small>
                </div>
                <small v-if="(v$.pld_problematica.minLength.$invalid && submitted) || v$.pld_problematica.$pending.$response" class="p-error">{{v$.pld_problematica.minLength.$message}}</small>
                    <small v-if="(v$.pld_problematica.required.$invalid && submitted) || v$.pld_problematica.$pending.$response" class="p-error">{{v$.pld_problematica.required.$message}}</small>
            </div>
        </div>
        <div class="flex justify-content-end">   
            <Button label="Cancelar" icon="pi pi-times" class="p-button-danger p-button-text mx-2" @click="cancel_onClick()"/>
            <Button type="submit" label="Guardar" class="mt-2" :loading="loadingCreate"/>
        </div>
    </form>
</template>

<script>
import { required,helpers,minLength, between  } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";



export default {
     emits : ['save_onEmit',"cancel_onEmit"],
    props: {
        PlanDidactico :{
            type : Object,
            default(){
                return {}
            }
        }, 
        service : null //inherited
    },
    setup: () => ({ v$: useVuelidate() }),
    data(){
        return{
            cicloList : [],
            selectedCiclo : null,

            horas_ens : this.PlanDidactico.pld_horas_ens,
            horas_apren: this.PlanDidactico.pld_horas_apren,
            pld_problematica : this.PlanDidactico.pld_problematica,
            pld_com_asignatura : this.PlanDidactico.pld_com_asignatura,

            submitted : false,
            loadingCreate : false
        }
    },
    validations() {
        return {
            // selectedCiclo: {
            //     required:  helpers.withMessage(
            //         () => `* Seleccione un ciclo válido`,
            //         required)
            // },
            pld_problematica:{
                required : helpers.withMessage(
                    () => `*Campo requerido`,
                    required
                ),
                minLength: helpers.withMessage(
                    ({
                        $invalid,
                        $params,
                    }) => `*El campo debería tener un minimo de ${$params.min} lo que  ${$invalid ? 'no' : ''} es válido`,
                    minLength(50)
                ),
            },
            pld_com_asignatura:{
                required : helpers.withMessage(
                    () => `*Campo requerido`,
                    required
                ),
                minLength: helpers.withMessage(
                    ({
                        $invalid,
                        $params,
                    }) => `*El campo debería tener un minimo de ${$params.min} lo que  ${$invalid ? 'no' : ''} es válido`,
                    minLength(50)
                ),
            },

            horas_ens: {
                required : helpers.withMessage(
                    () => `*Campo requerido`,
                    required
                ),
                between: helpers.withMessage(
                    ({
                        $params,
                    }) => `* Numero debe ser >= ${$params.min}`,
                    between(1, 9999)
                ),
            },
            horas_apren: {
                required : helpers.withMessage(
                    () => `*Campo requerido`,
                    required
                ),
            },
        }
    },

    methods:{
        async handleSubmit(isFormValid) {

            try{
                this.submitted = true;
                if (!isFormValid) {
                    return;
                }
                this.loadingCreate = true;
                await new Promise(resolve => {
                    setTimeout(async () => {
                        this.loadingCreate = false;
                        var codigo  = this.PlanDidactico.pld_codigo;
                        await this.service.updatePlan(codigo,
                        {
                            codcil :  this.PlanDidactico.pld_codcil,
                            ciclo : this.PlanDidactico.pld_nomcil,
                            problematica: this.pld_problematica,
                            comp_asignatura: this.pld_com_asignatura,
                            horas_ens: this.horas_ens,
                            horas_apren: this.horas_apren,
                            codigo : codigo
                            }).then(result=> this.$emit('save_onEmit', result)
                        );
                        resolve();
                    }, 1000);
                    });
            }catch(ex){
                console.log(ex);
            }
        },
        async cancel_onClick(){
            this.$emit("cancel_onEmit");
        }, 
    }
}
</script>