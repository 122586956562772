<template>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <div class="p-fluid">
            <div class="field">
                <label for="prp_nombre" :class="{'p-error':v$.ePractica.prp_nombre.$invalid && submitted}">Nombre de práctica</label>
                <Textarea for="prp_nombre" v-model="ePractica.prp_nombre" :autoResize="true" rows="3" cols="30" :class="{'p-invalid':v$.ePractica.prp_nombre.$invalid && submitted}"/>
                <div class="flex justify-content-end" :class="{'text-danger': (ePractica.prp_nombre.length > 350)}">
                    <small>{{ePractica.prp_nombre.length}}/350</small>
                </div>
                <small v-if="(v$.ePractica.prp_nombre.minLength.$invalid && submitted) || v$.ePractica.prp_nombre.$pending.$response" class="p-error">{{v$.ePractica.prp_nombre.minLength.$message}}</small>
                <small v-if="(v$.ePractica.prp_nombre.required.$invalid && submitted) || v$.ePractica.prp_nombre.$pending.$response" class="p-error">{{v$.ePractica.prp_nombre.required.$message}}</small>
            </div>
            <div class="field">
                <label for="prp_lugar" :class="{'p-error':v$.ePractica.prp_lugar.$invalid && submitted}">Lugar de práctica</label>
                <Textarea for="prp_lugar" v-model="ePractica.prp_lugar" :autoResize="true" rows="3" cols="30" :class="{'p-invalid':v$.ePractica.prp_lugar.$invalid && submitted}"/>
                <div class="flex justify-content-end" :class="{'text-danger': (ePractica.prp_lugar.length > 350)}">
                    <small>{{ePractica.prp_lugar.length}}/350</small>
                </div>
                <small v-if="(v$.ePractica.prp_lugar.minLength.$invalid && submitted) || v$.ePractica.prp_lugar.$pending.$response" class="p-error">{{v$.ePractica.prp_lugar.minLength.$message}}</small>
                <small v-if="(v$.ePractica.prp_lugar.required.$invalid && submitted) || v$.ePractica.prp_lugar.$pending.$response" class="p-error">{{v$.ePractica.prp_lugar.required.$message}}</small>
            </div>

            <div class="formgrid grid">
                <div class="col-8 ">
                    <div class="field">
                        <label for="prp_docente" :class="{'p-error':v$.ePractica.emp_nombres_apellidos.$invalid && submitted}">Responsable</label>
                        <div for="prp_docente" class="p-inputgroup">
                            <Button label="Buscar" @click="openDialogEmpleado_onClick()"/>
                            <InputText placeholder="Docente" disabled  :value="ePractica.emp_nombres_apellidos"/>
                        </div>
                        <small v-if="(v$.ePractica.emp_nombres_apellidos.required.$invalid && submitted) || v$.ePractica.emp_nombres_apellidos.$pending.$response" class="p-error">{{v$.ePractica.emp_nombres_apellidos.required.$message}}</small>
                    </div>
                </div>
                <div class="col-4">
                    <div class="field">
                        <label for="prp_total_horas" :class="{'p-error':v$.ePractica.prp_lugar.$invalid && submitted}">Horas</label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-clock text-primary"></i>
                            </span>
                            <InputText for="prp_total_horas"  v-model="ePractica.prp_total_horas" class="text-right" />
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-content-end">
            <Button label="Cancelar" icon="pi pi-times" class="p-button-danger p-button-text mx-2" @click="cancel_onClick()"/>
            <Button type="submit" label="Guardar" class="mt-2 px-6" :loading="loadingCreate"/>
        </div>
        
    </form>


     <!--Begin : Empleado Dialog -->
    <Dialog v-model:visible="isDialogEmpleado" :breakpoints="{'1360px': '95vw'}" :style="{width: '50vw'}" :modal="true">
        <template #header>
            <h5 class="mb-3 font-bold">Buscar Empleado</h5>
        </template>
        <EmpleadoDataTable  v-model:selectedItems="resultSelectedEmpleado" :Mode="'single'"> </EmpleadoDataTable>
     
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times"  class="p-button-text" @click="cancelEmpleado_onClick()"/>
            <Button label="Aceptar" icon="pi pi-check" class=""  @click="selectedEmpleado_onClick()"  />
        </template>
    </Dialog>
    <!-- End : Empleado Dialog -->
</template>

<script>
import { required,helpers,minLength  } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import EmpleadoDataTable from '../../Generales/EmpleadoDt.vue';

export default {
  components: { EmpleadoDataTable },

    emits: ['success_onEmit', "cancel_onEmit"],
    setup: () => ({ v$: useVuelidate() }),
    props:{
        Practica : {
             type:  Object,
             default(){
                 return {}
             }
        },
        Service : null //inherited
     },
     data(){
         return{
            ePractica : this.Practica,
            submitted : false,
            loadingCreate: false,
            isDialogEmpleado : false,
            resultSelectedEmpleado : null
         }
    },
    validations() {
        return {
            ePractica : {
                prp_nombre :{
                    required : helpers.withMessage(
                        () => `*Campo requerido`,
                        required
                    ),
                    minLength: helpers.withMessage(
                        ({
                            $invalid,
                            $params,
                        }) => `*El campo debería tener un minimo de ${$params.min} lo que  ${$invalid ? 'no' : ''} es válido`,
                        minLength(10)
                    )
                },
                prp_lugar :{
                    required : helpers.withMessage(
                        () => `*Campo requerido`,
                        required
                    ),
                    minLength: helpers.withMessage(
                        ({
                            $invalid,
                            $params,
                        }) => `*El campo debería tener un minimo de ${$params.min} lo que  ${$invalid ? 'no' : ''} es válido`,
                        minLength(10)
                    )
                },
                emp_nombres_apellidos : {
                    required: helpers.withMessage(
                        () => `*Campo requerido`,
                        required
                    ),
                },
                prp_codemp :{
                    required: helpers.withMessage(
                        () => `*Campo requerido`,
                        required
                    ),
                }

            }
        }
    },

    methods:{
        openDialogEmpleado_onClick(){
            this.isDialogEmpleado =  true;
        },
        cancelEmpleado_onClick(){
            this.isDialogEmpleado =  false;
        },
        selectedEmpleado_onClick(){
            var result =  {... this.resultSelectedEmpleado }
            this.ePractica.prp_codemp =  result.emp_codigo
            this.ePractica.emp_nombres_apellidos =  result.emp_nombres_apellidos
            this.isDialogEmpleado =  false;
            
        },
        async handleSubmit(isFormValid){
            try{
                this.submitted = true;
                if (!isFormValid) {
                    return;
                }

                if(this.ePractica.prp_codigo == 0) //New
                    await this.Service.createPractica(this.ePractica.prp_codpld,this.ePractica).then(res => this.ePractica = res);
                else
                     await this.Service.updatePractica(this.ePractica.prp_codigo,this.ePractica).then(res => this.ePractica = res);
                this.$emit("success_onEmit");
            }
            catch(ex){
                this.$catchError(ex);
            }
        },
        async cancel_onClick(){
            this.$emit("cancel_onEmit");
        }, 
    }
}
</script>