<template>
    <!--Component : Agregar Fuente -->
    <Dialog v-model:visible="fueVisible" :style="{width: '450px'}" header="FUENTE DE INFORMACIÓN" :modal="true" class="p-fluid" @hide= "hide_event" >
        
        <div class="field">
            <label for="nombre">Título</label>
            <InputText id="nombre" v-model.trim="fuente.fin_nombre" required="true" autofocus :class="{'p-invalid': submitted && !fuente.fin_nombre}"  placeholder="Nombre de fuente" />
            <small class="p-error" v-if="submitted && !fuente.fin_nombre">* Titulo requerido.</small>
        </div>
        <div class="field">
            <label for="enlace">Enlace</label>
            <Textarea id="enlace" v-model="fuente.fin_enlace" required="true" rows="3" cols="20" :autoResize="true"  placeholder="URL, Video, otro enlace virtual o descripción" :class="{'p-invalid': submitted && !fuente.fin_enlace}" />
             <small class="p-error" v-if="submitted && !fuente.fin_enlace">* Enlace o descripción requerido.</small>
        </div>

        <div class="field">
            <label for="cod_tipo_fuente" class="p-mb-3">Tipo</label>
            <Dropdown id="cod_tipo_fuente" v-model="fuente.tif" :options="tipoFuenteList" placeholder="Seleccione" @change="fuente.fin_codtif=$event.value.tif_codigo" 
            :class="{'p-invalid': submitted && !fuente.tif}" >
                <template #value="slotProps">
                    <div  v-if="slotProps.value">
                        <span>{{slotProps.value.tif_nombre}}</span>
                    </div>
                    <span v-else>
                        {{slotProps.placeholder}}
                    </span>
                </template>
                <template #option="slotProps">
                    <span>{{slotProps.option.tif_nombre}}</span>
                </template>
            </Dropdown>
            <small class="p-error" v-if="submitted && !fuente.tif">* Tipo requerido.</small>

        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-danger p-button-text" @click="cancel_onClick"/>
            <Button label="Guardar" icon="pi pi-check"  @click="save_onClick" />
        </template>
    </Dialog>
</template>
<script>

    export default {
        name:  "Fuente",
        inheritAttrs: false,
        emits : ["update:isVisible", 'cancel_onClick','save_onClick'],
        props: {
            isVisible :{
                type : Boolean,
                default :  false
            }, // Visible
            tipoFuenteList: {
                type : Array,
                default(){ 
                    return []
                }
            },
            itemSelected: { //For Edit Mode
                type : Object,
                default(){ 
                    return {
                        fin_codigo : 0,
                        fin_nombre : "",
                        fin_enlace : "",
                        tif : [],
                        fin_codtif : 0,
                        fin_codpln : 0
                    }
                }
                
            }
        },
        data(){
            return{
                fueVisible :  this.isVisible,
                fuente : this.itemSelected,
                
                submitted: false,
            }
        },

        created() {
        },

        async mounted() {
           
        },
        methods:{
            cancel_onClick(){
                this.$emit('update:isVisible', !this.isVisible)
                this.$emit('cancel_onClick')
                this.fueVisible =  false;
            },
            hide_event(){
                this.$emit('cancel_onClick')
            },
            save_onClick(){
                this.submitted = true;
                if(!this.fuente.fin_nombre || !this.fuente.fin_nombre.trim() || !this.fuente.tif || !this.fuente.fin_enlace.trim()){
                    return;
                }
                this.$emit('save_onClick', this.fuente)
                this.fueVisible =  false;
                 this.submitted = false;
            },
        },

        updated() {
            if (this.isVisible) {
                this.fueVisible = this.isVisible;
                this.fuente =  this.itemSelected;
            }
        }


    }
</script>
