// JSDoc (http://usejsdoc.org/)
/** @module RevisionPlanService */

export default class revisionPlanService{
    /**
     * @param axios Axios Instance
     */
     constructor(axios){
        this.axios =  axios;
    }




    //#region  Revisión Plan Didáctico
    /**
     * Crear revision de plan academico
     * @param {json} data Parámetros de Entrada.
     * @param {Integer} data.rpd_codpld  Codigo de plan default OUT
     * @param {Integer} data.rpd_codusr  Usuario creador
     * @param {String}  data.rpd_codigo  Codigo de Revision OUT
     * @param {String}  data.rpd_rev_num Revision numero de OUT
     * @return {json} JsonObject
     */
    async  createRevision(data){
        return this.axios.post('/RevisionPlan', data, {
            header: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data)
    } 

    /**
     * Actualizar revision de plan academico (Finalizar)
     * @param {json} data Parámetros de Entrada.
     * @param {Integer} data.rpd_codigo  Codigo de revision
     * @param {Integer} data.rpd_mejora  Comentario de mejora
     * @param {String}  data.rpd_logro   Comentario de logros
     * @param {String}  data.rpd_estado Estado de la revision NOT NULL
     * @param {String}  data.codusr_revision usuario que realizó la finalización de revision
     * @return {json} JsonObject
     */
    async updateRevision(data){
        return this.axios.put(`/RevisionPlan/${data.rpd_codigo}`, data, {
            header: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data);
    }

    /**
     * Imprimir/Extraer archivo de revision de plan academico (Finalizar)
     * @param {Integer} codigo  Codigo de revision
     * @return {blob} blobObject
     */
    async printRevision(codigo){
        return this.axios({
            url: `/RevisionPlan/Print/${codigo}`,
            method: 'GET',
            responseType: 'blob', // important
        }).then((res) =>res);
    }
    /**
     * Obtener lista de revision de un plan didáctico
     * @param {Integer} codPlan Código de plan didáctico
     * @return {json} JsonObjectList
     */
    async getAllRevision(codPlan){
        return this.axios.get(`/RevisionPlan/GetAll/${codPlan}`).then(res => res.data);
        
    }

    /**
     * Obtener una revisión de plan
     * @param {Integer} codigo Código de Revision
     * @return {json} JsonObject
     */
    async getRevision(codigo){
        return this.axios.get(`/RevisionPlan/GetById/${codigo}`).then(res => res.data);
        
    }


     /**
     * Crear/Editar un criterio de detalle de revision
     * @param {Integer}  Codigo  Codigo de revision.
     * @param {json} data Parámetros de Entrada.
     * @param {Integer} data.rdp_codrpd  codigo de revisoion
     * @param {String} data.rdp_codplb   Codigo de bloque de plan
     * @param {Integer} data.rdp_comentario  Comentario de criterio
     * @param {Integer} data.rdp_codigo  codigo de revision detalle OUT
     * @return {json} JsonObject
     */
      async upsertRevision(codigo,data) {
        return this.axios.put(`/RevisionPlan/Details/${codigo}`, data, {
            header: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data);
    }
    /**
     * Obtener lista de resultados de revisión
     * @param {Integer} codigo Código de Revision
     * @return {json} JsonObject
     */
    async getRevisionDetalle(codigo){
        return this.axios.get(`/RevisionPlan/GetDetalle/${codigo}`).then(res => res.data);
        
    }




    //Get criterios de evaluacion del criterio del esquema actual
   async getRevisionCriterio(codigoPlan){
      return this.axios.get(`/RevisionPlan/GetRevCriterio/${codigoPlan}`).then(res => res.data);
   }

    //Get Escala de los criterios del esquema activo
   async getRevisionEscala(){
      return this.axios.get(`/RevisionPlan/GetRevEscala/`).then(res => res.data);
   }
}