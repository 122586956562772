<template>
   <form class="p-2" @submit.prevent="saveNivel_onClick">
        <div class="grid">
        </div>
        <div class="field">
            <Textarea id="plb_evidencia_logro" v-model="eNivelBloque.pbn_contenido" class="inputfield w-full" cols="20" rows="5" :autoResize="true"/>
            <div class="flex justify-content-between" :class="{'text-danger': ( (eNivelBloque.pbn_contenido || '' ).length > 4000)}">
                <span class="form-text text-muted">Definición del nivel de dominio </span>
                <small>{{(eNivelBloque.pbn_contenido || '' ).length}}/4000</small>
            </div>
        </div>
         <div class="flex justify-content-end">
            <Button label="Cancelar" icon="pi pi-times" class="p-button-danger p-button-text mx-2" @click="cancel_onClick()"/>
            <Button type="submit" label="Guardar" class="mt-2 px-6" />
        </div>
    </form>
</template>

<script>

import moment from 'moment'
export default {
    emits : ["success_onEmit","cancel_onEmit" ],
    props: {
        Nivel :{
            type : Object,
            default(){
                return {}
            }
        },
        Service : null //inherited
    },

    data(){
        return{
            eNivelBloque : this.Nivel
        }
    },
    methods : {
        async saveNivel_onClick(){
            try {
                await this.Service.updateBloqueNivel(0,this.eNivelBloque).then(result => this.eNivelBloque =  result)
                 this.$emit("success_onEmit");
            }catch(ex)
            {
                this.$catchError(ex);
            }
        }, 
        async cancel_onClick(){
            this.$emit("cancel_onEmit");
        }, 
        DateFormat(data) {
            return moment(data).format('DD/MM/YYYY')
        },
    }
}
</script>