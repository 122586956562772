<template>
    <form class="p-fluid" @submit.prevent="handleSubmit(!v$.$invalid)">
        <div class="field grid">
            <div class="col-12 lg:col-9 xl:col-6 xl:col-offset-3">
                <h5 class="font-semibold text-lg">Bloque de aprendizaje</h5>
            </div>
        </div>
        <div class="formgird grid">
             <div class="col-12 mb-2 md:col-3 md:mb-0 text-right">
                 <label for="pla_horas_ens" :class="{'p-error':v$.PlanBloque.plb_horas_semanal.$invalid && submitted}" >Horas Semanales</label>
             </div>
            <div class="col-12 md:col-3 xl:col-1">
               
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-clock text-primary"></i>
                    </span>
                    <InputText for="pla_horas_ens" v-model="PlanBloque.plb_horas_semanal"  :class="{'p-invalid':v$.PlanBloque.plb_horas_semanal.$invalid && submitted}" class="text-right"/>
                    
                </div>
                <small v-if="(v$.PlanBloque.plb_horas_semanal.minValue.$invalid && submitted) || v$.PlanBloque.plb_horas_semanal.$pending.$response" class="p-error">{{v$.PlanBloque.plb_horas_semanal.minValue.$message}}</small>
            </div>
        </div>
        <div class="field grid">
            
            <div class="col-12 mb-2 md:col-3 md:mb-0 text-right">
                  <label for="plb_nombre_tec" class="text-right " :class="{'p-error':v$.PlanBloque.plb_nombre_tec.$invalid && submitted}">* Título Formal</label>
            </div>
            <div class="col-12 md:col-9 xl:col-6">
                <InputText id="plb_nombre_tec" v-model="PlanBloque.plb_nombre_tec" class="inputfield w-full" :class="{'p-invalid':v$.PlanBloque.plb_nombre_tec.$invalid && submitted}"/>
    
                <small v-if="(v$.PlanBloque.plb_nombre_tec.maxLength.$invalid && submitted) || v$.PlanBloque.plb_nombre_tec.$pending.$response" class="p-error">{{v$.PlanBloque.plb_nombre_tec.maxLength.$message}}</small>
                <small v-if="(v$.PlanBloque.plb_nombre_tec.required.$invalid && submitted) || v$.PlanBloque.plb_nombre_tec.$pending.$response" class="p-error">{{v$.PlanBloque.plb_nombre_tec.required.$message}}</small>
            </div>
        </div>
        <div class="field grid">
            <div class="col-12 mb-2 md:col-3 md:mb-0 text-right">
                <label for="plb_nombre_est" class="text-right" :class="{'p-error':v$.PlanBloque.plb_nombre_est.$invalid && submitted}">* Título Común</label>
            </div>
            <div class="col-12 md:col-9 xl:col-6">
                <InputText id="plb_nombre_est"  v-model="PlanBloque.plb_nombre_est" class="inputfield w-full" :class="{'p-invalid':v$.PlanBloque.plb_nombre_est.$invalid && submitted}"/>
                <span class="form-text text-muted">Título que llame la atención al estudiante, por ser comprensible o incluso divertido </span>
                <small v-if="(v$.PlanBloque.plb_nombre_est.maxLength.$invalid && submitted) || v$.PlanBloque.plb_nombre_est.$pending.$response" class="p-error">{{v$.PlanBloque.plb_nombre_est.maxLength.$message}}</small>
                <small v-if="(v$.PlanBloque.plb_nombre_est.required.$invalid && submitted) || v$.PlanBloque.plb_nombre_est.$pending.$response" class="p-error">{{v$.PlanBloque.plb_nombre_est.required.$message}}</small>
            </div>
        </div>
        <Divider type="dashed"/>
        <div class="field grid">
            <div class="col-12 mb-2 md:col-3 md:mb-0 text-right">
                <label for="plb_criterio_eval" class="text-right">Criterio de Evaluación</label>
            </div>
            <div class="col-12 md:col-9 xl:col-6">
                <Textarea id="plb_criterio_eval" v-model="PlanBloque.plb_criterio_eval" class="inputfield w-full" cols="20" rows="5" :autoResize="true" placeholder="Estructura: Verbo en presente + objeto conceptual + condición de referencia y forma de apropiación."/>
                <div class="flex justify-content-between" :class="{'text-danger': (PlanBloque.plb_criterio_eval.length > 1000)}">
                    <small class="form-text text-muted"> Estructura: Verbo en presente + objeto conceptual + condición de referencia y forma de apropiación.</small>
                    <small>{{PlanBloque.plb_criterio_eval.length}}/1000</small>
                </div>
            </div>
        </div>

        <div class="field grid">
            <div class="col-12 mb-2 md:col-3 md:mb-0 text-right">
                <label for="plb_evidencia_logro" class="text-right">Evidencia de Logro</label>
            </div>
            <div class="col-12 md:col-9 xl:col-6">
                <Textarea id="plb_evidencia_logro" v-model="PlanBloque.plb_evidencia_logro" class="inputfield w-full" cols="20" rows="5" :autoResize="true"/>
                <div class="flex justify-content-between" :class="{'text-danger': (PlanBloque.plb_evidencia_logro.length > 1000)}">
                    <span class="form-text text-muted">(Semana 6 - ponderación 40%)</span>
                    <small>{{PlanBloque.plb_evidencia_logro.length}}/1000</small>
                </div>
                
            </div>
        </div>

        <div class="field grid">
            <div class="col-12 mb-2 md:col-3 md:mb-0 text-right">
                <label for="plb_instrumento_eval" class="text-right">Instrumento de Evaluación</label>
            </div>
            <div class="col-12 md:col-9 xl:col-6">
                <Textarea id="plb_instrumento_eval" v-model="PlanBloque.plb_instrumento_eval"  class="inputfield w-full" cols="20" rows="5" :autoResize="true"/>
                <div class="flex justify-content-between" :class="{'text-danger': (PlanBloque.plb_instrumento_eval.length > 1000)}">
                    <span class="form-text text-muted">Para la evidencia de logro del criterio</span>
                    <small>{{PlanBloque.plb_instrumento_eval.length}}/1000</small>
                </div>
                
            </div>
        </div>
        <div class="grid">
            <div class="col-12 lg:col-9 xl:col-6 xl:col-offset-3 md:mb-0 text-right">
                <div class="flex justify-content-center">
                    
                    <Button label="Cancelar" class="p-button-danger p-button-text mr-2" @click="$emit('cancel_onEmit')" />
                    <Button label="Guardar"  type="submit" class="mr-2" />
                </div>
            </div>
        </div>
            
    </form>
</template>

<script>
import { required,helpers,maxLength , minValue } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
   
    emits : ["cancel_onEmit", "save_onEmit"],
    props:{
        ePlanBloque : {
            type: Object,
            default(){
                return {}
            }
        },
        service : null //inherited
    },
    setup: () => ({ v$: useVuelidate() }),
    data(){
        return {
            PlanBloque: this.ePlanBloque,
            submitted : false
        }
    },
    validations() {
        return {
            PlanBloque : {
                plb_nombre_tec : {
                    required : helpers.withMessage(
                        () => `*Campo requerido`,
                        required
                    ),
                    maxLength: helpers.withMessage(
                        ({
                            $invalid,
                            $params,
                        }) => `*El campo debería tener un máximo de ${$params.max} lo que  ${$invalid ? 'no' : ''} es válido`,
                        maxLength(150)
                    ),
                },
                plb_horas_semanal : {
                    required : helpers.withMessage(
                        () => `*Campo requerido`,
                        required
                    ),
                    minValue: helpers.withMessage(
                        ({
                            $params,
                        }) => `*Debe ser mayor o igual a ${$params.min}`,
                        minValue(1)
                    ),
                },
                plb_nombre_est : {
                    required : helpers.withMessage(
                        () => `*Campo requerido`,
                        required
                    ),
                    maxLength: helpers.withMessage(
                        ({
                            $invalid,
                            $params,
                        }) => `*El campo debería tener un máximo de ${$params.max} lo que  ${$invalid ? 'no' : ''} es válido`,
                        maxLength(150)
                    ),
                },
            }
        }
    },
    created(){
        
    },

    methods:{
        async handleSubmit(isFormValid){
            try{
                this.submitted = true;
                if (!isFormValid) {
                    return;
                }
                await this.service.createBloque(this.PlanBloque.plb_codpld,this.PlanBloque).then(res => this.PlanBloque = res);
                this.$emit("save_onEmit");
            }
            catch(ex){
                this.$catchError(ex);
            }
        }
    }
}
</script>