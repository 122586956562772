
export default class CompetenciaService {
    constructor(axios) {
        this.axios = axios;
    }


    async getAllCompetencia() {
        return  this.axios.get('/CompetenciaGral').then(res => res.data);
    }
}
