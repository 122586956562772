<template>
    <form class="p-2" @submit.prevent="saveEje_onSubmit">
        <div class="grid">
            <div class="col-12">
                <div class="flex align-items-center justify-content-between">
                    <div class="text-base font-semibold">N° de Semana</div>
                    <span class="font-semibold">{{Semana.pbd_semana}}</span>
                </div>
                <Divider type="dashed" class="p-divider-muted"/>
                <div class="flex align-items-center justify-content-between">
                    <div class="text-base font-semibold">Fecha de Desde</div>
                    <span class="font-semibold">{{DateFormat(Semana.pbd_fecha_desde)}}</span>
                </div>
                <Divider type="dashed" class="p-divider-muted"/>
                <div class="flex align-items-center justify-content-between">
                    <div class="text-base font-semibold">Fecha de Hasta</div>
                    <span class="font-semibold">{{DateFormat(Semana.pbd_fecha_hasta)}}</span>
                </div>
                <Divider type="dashed" class="p-divider-muted"/>
            </div>
        </div>
        <div class="field">
            <Textarea id="plb_evidencia_logro" v-model="eEjeBloque.ejb_contenido" class="inputfield w-full" cols="20" rows="5" :autoResize="true"/>
            <div class="flex justify-content-between" :class="{'text-danger': (eEjeBloque.ejb_contenido.length > 4000)}">
                <span class="form-text text-muted">Definición del eje</span>
                <small>{{eEjeBloque.ejb_contenido.length}}/4000</small>
            </div>
        </div>
         <div class="flex justify-content-end">
            <Button label="Cancelar" icon="pi pi-times" class="p-button-danger p-button-text mx-2" @click="cancel_onClick()"/>
            <Button type="submit" label="Guardar" class="mt-2 px-6" />
        </div>
    </form>
    
</template>

<script>

import moment from 'moment'

export default {
    emits : ["success_onEmit", "cancel_onEmit"],
    props: {
        Eje :{
            type : Object,
            default(){
                return {}
            }
        },
        Semana : {
            type : Object,
            default(){
                return {}
            }
        },
        Service : null //inherited
    },

    data(){
        return{
            eEjeBloque : this.Eje
        }
    },


    methods : {
        async saveEje_onSubmit(){
            try {
                await this.Service.updateBloqueEje(this.eEjeBloque).then(result => this.eEjeBloque =  result)
                 this.$emit("success_onEmit");
            }catch(ex)
            {
                this.$catchError(ex);
            }
        }, 

        async cancel_onClick(){
            this.$emit("cancel_onEmit");
        },  
        DateFormat(data) {
            return moment(data).format('DD/MM/YYYY')
        },
    }
}
</script>