<template>
    <Dialog v-model:visible="isVisibleReview" :modal="true" :style="{width: '460px'}" ref="dialog">
        <template #header >
            <div class="flex flex-column align-items-center flex-grow-1">
                <span class="text-xl md:text-2xl uppercase">Historial de Revisión</span>
                 <div class="flex flex-column align-items-center w-8 " style="float:right">
                    <span class="font-semibold  text-gray-600"></span>
                </div>
            </div>
        </template>

        <DataTable :value="revisionList" :rowHover="true" stripedRows responsiveLayout="scroll" @row-dblclick="rowRevision_dblClick($event)" selectionMode="single">
            <template #loading>
                Cargando listado de revisión. Espere por favor... 
            </template>
            <template #empty>
                <p class="font-italic text-center">No hay revisión para este plan académico.</p>
            </template>
            <Column  header=""  :style="'width:1rem'" class="px-1">
               <template #body="{data}">
                    <span class="label label-lg  label-inline font-semibold" 
                    :class="'label-light-' + (data.estado === 'S' ? 'primary' : (data.estado === 'N' ? 'danger' : 'warning') )">
                        <i class="mr-1 pi " :class="(data.estado === 'S' ? 'pi-check' : (data.estado === 'N' ? 'pi-times' : 'pi-pencil') )"/>
                         Rev{{data.rev_num}}
                    </span>
                </template>
            </Column>
            <Column  header="Presentación" >
                <template #body="{data}">
                    <span class="font-bold text-primary">{{fx_DateFormat(data.fecha_presentacion)}}</span>
                </template>
            </Column>
             <Column  header="Revisión">
                <template #body="{data}">
                    <span class="font-bold text-primary">{{fx_DateFormat(data.fecha_revision)}}</span>
                </template>
            </Column>
            
            <Column header="" >
                <template #body="{data}">
                    <a href="javascript:void(0)" @click="upsertRevision_onClick(true, data)">
                        <i class="pi " :class=" data.estado === null  ?  'pi-pencil' :  'pi-chevron-right' " />
                    </a>
                </template>
            </Column>
        </DataTable>
        <div class="h-3px w-100 bg-light-danger p-0 mb-1" ></div>
        <div class="flex w-full justify-content-center border-round bg-light-primary py-2 px-4 border-dashed-custom  border-primary" v-if="PlanDidactico.pld_estado == 'R' "> 
            <div class="block text-center ">
                <a href="javascript:void(0)" class="font-bold ml-1 text-primary" @click="upsertRevision_onClick()">Nueva Revisión</a>
            </div> 
        </div>
    </Dialog>
    <!--Dialog Revision: Modal para crear/actualizar nueva revision -->
    <DialogRevision key="1" ref="dialRevision"  @hide="revision_onHide()"  v-model:Revision="selectedRevision"  :Service="revisionPlanService"/>
</template>

<script>


import moment from 'moment'
import DialogRevision from "@/components/LaborDocente/Revision/DialogRevision"

import RevisionPlanService from "@/service/revisionPlanService"

export default {
    name : "RevisionData",
    props:{
        PlanDidactico :{
            type: Object,
            default(){
                return{
                    pld_estado :  ""
                }
            }
        },
        Comision: null,
        
    },

    revisionPlanService : null,
    components:{
        "DialogRevision" : DialogRevision
    },
    data(){
        return {
            revisionList: [
                // { fecha_revision : "2021-01-01", fecha_presentacion : "2021-01-05", finalizado : "N" },
                // { fecha_revision : "2021-01-01", fecha_presentacion : "2021-01-05", finalizado : null },
                // { fecha_revision : "2021-01-01", fecha_presentacion : "2021-01-05", finalizado : "S" }
            ],

            selectedRevision: {},
            isVisibleReview: false,
            
            Carrera: null,
            Facultad: null
        }
    },
    created(){
        this.revisionPlanService =  new RevisionPlanService(this.axios);
    },
    async mounted(){
        await this.fx_Init();
       
        
    },
    methods:{
        async fx_Init(){
            await this.revisionPlanService.getAllRevision(this.PlanDidactico.pld_codigo)
            .then(res => this.revisionList =  res)
        },
        async openReview_onclick(carrera, facultad){
            this.Carrera = carrera
            this.Facultad =  facultad
            this.isVisibleReview =  true
            await this.fx_Init()
        }, 

        async revision_onHide(){
            this.isVisibleReview =  true
            await this.fx_Init()
        },
        async upsertRevision_onClick(editMode  = false, dataSelected = null){
            var codigoRevision = 0
            if(!editMode) 
            { // New Element
                var exists =  this.revisionList.findIndex(x=> x.estado === null);
                if(exists >= 0){
                    this.$toast.add({severity:'error', summary:'¡Error!', detail:'Existe una revision en proceso', life: 5000});
                    return;
                }
                exists = this.revisionList.findIndex(x=> x.estado === "S")
                if(exists >= 0){
                    this.$toast.add({severity:'error', summary:'¡Error!', detail:'Ya existe una revisión aprobada para esta planficación, recarge la planificación para visualizar los cambios.', life: 5000});
                    return;
                }

                this.selectedRevision = {}
            }else{ // Edit/View Element
                codigoRevision =  dataSelected.codigo
            }


            this.isVisibleReview = false
            await this.$refs.dialRevision.openDialog_onClick(this.PlanDidactico, this.Comision, this.Carrera, this.Facultad, codigoRevision);
            
        },

        async rowRevision_dblClick(event){
            await this.upsertRevision_onClick(true, event.data)
        },
        fx_DateFormat(data) {
            var startDate = new Date(2021, 1, 1)
            var dateData = new Date(data);
            if(dateData < startDate)
                return "-"
            return moment(data).format('DD/MM/YYYY')
        },

        fx_Estado(status){
            if(status == "S"){
                return "Completado";
            }
            else if(status == "N"){
                return "Rechazado"
            }else{
                return "En Revisión"
            }
        }

    }


}
</script>


