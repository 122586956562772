<template>
    <div class="p-card mt-2 " v-if="isCreateBloque">
        <!-- Begin: If is create -->
        <UpsertBloque   @cancel_onEmit="cancelBloque_onClick" :ePlanBloque="selectedPlanBloque" @save_onEmit="saveBloque_onClick" :service="planDidacticoService" />
        <!-- End: If is create -->
    </div>
    
    <div class="grid mt-2" v-if="isExistsBloque && !isCreateBloque">
        <div class="col-12">
            <Card class="border-top-1 border-primary pt-0">
                <template #content >
                    <div class="grid">
                        <div class="col-12">
                            <div class="flex">
                                <div class="flex-shrink-0  mr-3" v-tooltip.top="'Cómputo'">
                                    <div class="symbol symbol-50 symbol-lg-120 symbol-light-primary shadow-3">
                                        <span class="text-2xl symbol-label font-bold">B{{(bloqIndex+1)}}</span>
                                    </div>
                                </div>
                                <div class="flex-grow-1 "> 
                                    <div class="flex align-items-center justify-content-between flex-wrap">
                                        <div class="mr-2">
                                            <a href="javascript:void(0)" class="flex align-items-center mr-3 font-bold mr-1 text-lg text-gray-800 hover:text-primary" >
                                                <PixelIco v-tooltip.top="'Título formal'" /> {{selectedPlanBloque.plb_nombre_tec}}
                                            </a>

                                            <div class="flex flex-wrap my-2"> 
                                                <a href="javascript:void(0)" class="text-gray-800 mr-lg-8 mr-5 mb-2 font-semibold  mr-1 mb-2" >
                                                    <CompilingIco  v-tooltip.top="'Título común'"/> {{selectedPlanBloque.plb_nombre_est}}
                                                </a> 
                                            </div>
                                        </div> 
                                        <div class="my-1">
                                            <!--- <Button label="Editar"  icon="pi pi-pencil" class="p-button-info  mr-2"  @click="editBloque_onClick()"/> --->
                                            <SplitButton label="Editar" icon="pi pi-pencil" class="p-button-info btn-light-primary" @click="editBloque_onClick()" :model="itemsButtonBloque"></SplitButton>   
                                        </div>
                                    </div>
                                    <div class="flex align-items-center flex-wrap justify-content-between">
                                        <span class="font-semibold ">Competencia (asignatura): </span>
                                        <div class="flex-grow-1 py-1 mr-1 text-gray-900 ">{{Competencia}}</div>
                                    </div>
                                        
                                </div>
                            </div>
                            <!--Begin: Details Bar -->
                            <Divider class="p-0 my-2"/>
                            <div class="flex  align-items-center flex-wrap">
                                <div class="flex align-items-center flex-lg-fill  w-6  lg:w-8rem">
                                    <span class="mr-3">
                                        <i class="pi pi-calendar  text-4xl  text-primary"></i>
                                    </span>
                                    <div class="flex flex-column text-gray-700 align-items-center">
                                        <span class="font-bold text-sm">Semanas</span>
                                        <span class="font-bold text-lg">{{selectedPlanBloque.plb_num_semanas}}</span>
                                    </div>
                                </div>

                                <div class="flex align-items-center flex-lg-fill   w-6  lg:w-8rem">
                                    <span class="mr-3">
                                        <i class="pi pi-calendar  text-4xl  text-primary"></i>
                                    </span>
                                    <div class="flex flex-column text-gray-700 align-items-center">
                                        <span class="font-bold text-sm">Horas Semanal</span>
                                        <span class="font-bold text-lg">{{selectedPlanBloque.plb_horas_semanal}}</span>
                                    </div>
                                </div>

                                <div class="flex align-items-center  flex-lg-fill  w-6  lg:w-8rem">
                                    <span class="mr-3">
                                        <i class="pi pi-clock text-4xl  text-primary"></i>
                                    </span>
                                    <div class="flex flex-column text-gray-700 align-items-center">
                                        <span class="font-bold text-sm">Horas Bloque </span>
                                        <span class="font-bold text-lg">{{selectedPlanBloque.plb_horas_total}}</span>
                                    </div>
                                </div>
                                <div class="flex align-items-center flex-lg-fill  w-6  lg:w-8rem ">
                                    <span class="mr-3">
                                        <i class="pi pi-share-alt text-4xl  text-primary"></i>
                                    </span>
                                    <div class="flex flex-column text-gray-700 align-items-center">
                                        <AvatarGroup>
                                            <Avatar v-for="com,index in Comision.slice(0,4)" :key="com" :label="com.emp_nombres_apellidos[0].toUpperCase()" shape="circle" size="large" class="p-avatar-hover" :style="generatorColor(index)"  v-tooltip.top="com.emp_nombres_apellidos"/>
                                            <Avatar :label="'+' + String(Comision.length - 4)" v-if="(Comision.length - 4 > 0)" shape="circle" size="large" class="p-avatar-hover"  :style="generatorColor(5)" v-tooltip.top="''"/>
                                        </AvatarGroup>
                                    </div>
                                </div>
                            </div>
                            <Divider class="p-0 my-1"/>
                             <!--Begin: Details Bar -->
                        </div>
                    </div>

                    <!--Begin: Criterios y Logros -->
                    <h5 class="font-bold mr-1 mt-1 text-lg text-gray-800 hover:text-primary">Criterios y Logros</h5>
                    <div class="grid">
                        <div class="col-12 lg:col-4 ">
                            <div class="flex flex-column border-round border-solid h-full">
                                <div class="flex align-items-center justify-content-between help">
                                    <div class="text-lg font-semibold flex align-items-center mr-2">
                                        <AngleDoubleIco/>
                                        <span class="text-gray-900"> Criterio de evaluación</span>
                                    </div>
                                    <a href="javascript:void(0)" class="btn btn-active-light-primary p-2" ><i class="pi pi-question-circle text-primary"/></a>
                                </div>
                                <div class="help-child font-italic text-primary p-1">
                                    Estructura: Verbo en presente + objeto conceptual + condición de referencia y forma de apropiación. Debe ir relacionado con la competencia; considere: De todo lo que el estudiante hará en este bloque, ¿qué es lo más importante que debe lograr? Luego, todas las actividades de enseñanza y de aprendizaje que diseñe abajo, deben apuntarle a que el estudiante cumpla, como mínimo, este criterio.
                                </div>
                                <div class="h-3px w-100 bg-primary p-0 m-0" ></div>
                                <p class="mr-4 p-2">{{selectedPlanBloque.plb_criterio_eval}}</p>
                            </div>
                        </div>

                        
                        <div class="col-12  lg:col-4 ">
                            <div class="flex flex-column border-round border-solid h-full">
                                <div class="flex align-items-center justify-content-between help">
                                    <div class="text-lg font-semibold flex align-items-center mr-2">
                                        <AngleDoubleIco/>
                                        <span class="text-gray-900"> Evidencia del logro del criterio</span> 
                                    </div>
                                    <a href="javascript:void(0)" class="btn btn-active-light-primary  p-2 help"  ><i class="pi pi-question-circle text-primary"/></a>
                                </div>
                                <div class="help-child font-italic text-primary p-1">
                                   Asigne una evidencia que permita que tanto el docente como el estudiante sepan que se ha cumplido el criterio; también debe evaluar todos los saberes (obsérvese el criterio y la competencia) y su ponderación será la de examen parcial 40%.
                                </div>
                                <div class="h-3px w-100 bg-primary p-0 m-0" ></div>
                                <p class="mr-4  p-2">{{selectedPlanBloque.plb_evidencia_logro}}</p>
                            </div>
                        </div>
                        
                        <div class="col-12  lg:col-4 ">
                            <div class="flex flex-column border-round border-solid h-full">
                                <div class="flex align-items-center justify-content-between help">
                                    <div class="text-lg font-semibold flex align-items-center mr-2">
                                        <AngleDoubleIco/>
                                        <span class="text-gray-900">Instrumento de evaluación</span>
                                    </div>
                                    <a href="javascript:void(0)" class="btn btn-active-light-primary  p-2" ><i class="pi pi-question-circle text-primary"/></a>
                                </div>
                                <div class="help-child font-italic text-primary p-1">
                                   El instrumento deberá permitir identificar logros y aspectos a mejorar que deben ser retroalimentados a los estudiantes; e incluir auto, co y heteroevaluación)
                                </div>
                                <div class="h-3px w-100 bg-primary p-0 m-0" ></div>
                                <p class="mr-4  p-2">{{selectedPlanBloque.plb_instrumento_eval}}</p>
                            </div>
                        </div>
                    </div>
                    <!--End: Criterios y Logros -->
                </template>
            </Card>

            <!--Begin: Detalle de Bloque -->
            <div class="p-card mt-3 border-top-1 border-primary">
                <div class="flex align-items-center justify-content-between flex-wrap">
                    <h5 class="font-bold mr-1 text-lg text-gray-800 hover:text-primary">Detalle Semanal
                        <small class="text-muted"> Ejes por semana ↓ </small>
                    </h5>
                    <div class="my-1">
                        <Button label="Nuevo" icon="pi pi-calendar"  class="mx-2 p-button-primary"  @click="editeBloqueDetalle_onClick()" />
                        <SplitButton label="Editar" icon="pi pi-pencil" class="p-button-info btn-light-primary" @click="editeBloqueDetalle_onClick(true)" :model="itemsButtonDetalle" v-if ="bloqDetalleIndex >= 0"></SplitButton>   
                    
                    </div>
                </div>
                <div class="h-3px w-100 bg-light-danger p-0 m-0" ></div>
                <TabView  @tab-change="tabDetalle_onChange($event)" v-if="bloqDetalleIndex >=0" v-model:activeIndex="bloqDetalleIndex"> 
                    <!--Begin: Semanas -->
                    <TabPanel  v-for="detalle, index in planBloqueDetalleList" :key="detalle" >
                        <template #header >
                            <i class="pi pi-calendar mr-1"></i>
                            <span v-if="detalle.pbd_niveles">Evaluación final de cómputo</span>
                            <span v-else><b class="hidden md:inline-flex">Sem. </b> {{detalle.pbd_semana}}</span>
                        </template>
                        <div class="grid mb-0">
                            <div class="col-6 md:col-3 ">
                                <div class="flex align-items-center justify-content-center">
                                    <div class="text-base font-semibold">Semana No.: </div>
                                    <span class="font-normal ml-2">{{index + 1}}</span>
                                </div>
                                <Divider type="dashed" class="p-divider-muted"/>
                            </div>
                            <div class="col-6 md:col-3 ">
                                <div class="flex align-items-center justify-content-center">
                                    <div class="text-base font-semibold">Ciclo semana No.: </div>
                                    <span class="font-normal ml-2">{{detalle.pbd_semana}}</span>
                                </div>
                                <Divider type="dashed" class="p-divider-muted"/>
                            </div>
                            <div class="col-6  md:col-3 ">
                                <div class="flex align-items-center justify-content-center">
                                    <div class="text-base font-semibold">Fecha Desde: </div>
                                    <span class="font-normal ml-2">{{DateFormat(detalle.pbd_fecha_desde)}}</span>
                                </div>
                                <Divider type="dashed" class="p-divider-muted"/>
                            </div>
                            <div class="col-6  md:col-3 ">
                                <div class="flex align-items-center justify-content-center">
                                    <div class="text-base font-semibold">Fecha Hasta: </div>
                                    <span class="font-normal ml-2">{{DateFormat(detalle.pbd_fecha_hasta)}}</span>
                                </div>
                                <Divider type="dashed" class="p-divider-muted"/>
                            </div>
                        </div>
                        <!--Begin: Ejes -->
                        <div class="ml-1" v-if="!detalle.pbd_niveles">
                            <div class="grid">
                                <div class="col-12  lg:col-6 " v-for="dEje in bloqueDetalleEjeList" :key="dEje">
                                    <div class="flex flex-column border-round border-solid h-full shadow-2">
                                        <div class="flex align-items-center justify-content-between m-1">
                                            <div class="text-lg font-semibold flex align-items-center mr-2">
                                                <AngleDoubleIco/>
                                                <span class="text-gray-900">{{dEje.epl_nombre}}</span></div>
                                            <a href="javascript:void(0)" class="btn btn-active-light-primary p-2"  
                                                @click="editDetalle_onClick(dEje, 'modalEje')" aria-haspopup="true" aria-controls="overlay_menu"><EditIco/></a>
                                        </div>
                                        <div class="h-3px w-100 bg-primary p-0 m-0" ></div>
                                        <div class="m-3" style="white-space: pre-wrap">{{dEje.ejb_contenido }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--End: Ejes -->

                        <!--Begin: Niveles -->
                        <div class="ml-1" v-else>
                            <div class="flex border-round bg-light-warning p-4 border-dashed-custom  border-warning mb-3" v-if="!bloqueNivelList"> 
                                <div class="block ">
                                    <a href="javascript:void(0)" class="font-bold">Evaluación</a>  No se a establecido los niveles de dominio
                                </div> 
                            </div>
                            <div class="grid">
                                <div class="col-12  lg:col-6" v-for="niv in bloqueNivelList" :key="niv">
                                    <div class="flex flex-column border-round border-solid h-full shadow-2">
                                        <div class="flex align-items-center justify-content-between mt-3">
                                            <div class="text-lg font-semibold flex align-items-center ml-2">
                                                <AngleDoubleIco/>
                                                <span class="text-gray-900">{{niv.nid_nombre}}</span>
                                                <small class="ml-2 text-muted"> {{niv.end_desc_pts}}</small>
                                            </div>
                                            <a href="javascript:void(0)" class="btn btn-active-light-primary  p-2"  @click="editDetalle_onClick(niv, 'modalNivel')"><EditIco/></a>
                                        </div>
                                        <div class="h-3px w-100 bg-light-danger p-0 m-0" ></div>
                                        <div class="m-3" :key="niv"
                                            :class="{ 'text-danger' :  !niv.pbn_contenido?.trim() }"
                                         style="white-space: pre-wrap">{{niv.pbn_contenido ||  niv.nid_descripcion}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Begin: Niveles -->

                        
                    </TabPanel>
                    <!--End: Semanas -->
                </TabView>
                <div class="flex border-round bg-light-warning p-3 mt-2 border-dashed-custom  border-warning" v-if="bloqDetalleIndex <= -1"> 
                    <div class="block ">
                        <a href="javascript:void(0)" class="font-bold text-primary"> Detalle de programación semanal</a>  No se ha establecido ninguna especificación del programa
                    </div> 
                </div>

                <!--Begin : Message -->
                <div class="flex justify-content-center border-round bg-light-warning p-2 mt-2 border-dashed-custom  border-warning p-component" v-if="!existsNiveles"> 
                    <div class="block">
                        <a href="javascript:void(0)" class="font-bold text-danger">Niveles de dominio.</a> Se han creado los niveles de dominio para este bloque pero no existe una semana registrada para evaluación.
                        <a href="javascript:void(0)" class="font-bold ml-1 text-danger " @click="editeBloqueDetalle_onClick()">Agregar Semana</a>
                    </div> 
                </div>
                <!--End : Message -->
            </div>
            <!--Begin: Detalle de Bloque -->

            <!--Begin: Detalle de Programación de practicas -->
            <div class="p-card mt-2 border-top-1 border-primary">
                <div class="flex align-items-center justify-content-between flex-wrap">
                    <h5 class="font-bold  text-lg text-gray-800 hover:text-primary">Prácticas
                        <small class="text-muted"> Requisito prácticas profesionales ↓ </small>
                    </h5>
                    <div class="my-0">
                        <Button label="Nuevo"  icon="pi pi-plus-circle" class="p-button-primary  mr-2" @click="addEditPractica_onClick()"  />
                    </div>
                </div>
                <Divider class="p-0 "/>
                <div class="grid px-4">
                     <div class="col-12  border-solid" v-for="progra in practicaList" :key="progra">
                        <div class="flex align-items-center">
                            <div class="block symbol symbol-circle symbol-40px">
                                <div class="symbol-label">
                                    <MessageIco/>
                                </div>
                            </div>
                            <span class="text-base ml-2 font-bold align-items-center"> {{progra.prp_nombre}}</span>
                        </div>
                        <span class="ml-5 mb-3 font-semibold text-muted">Duración: <span class="text-gray-900">{{progra.prp_total_horas}} horas</span></span>
                        <div class="ml-5 mb-1 font-semibold text-muted">Responsable: <span class="mx-2 font-semibold text-gray-900">{{progra.emp_nombres_apellidos}}</span> </div>
                        <div class="flex justify-content-between align-items-center  border-round  p-2 ml-5  border-dashed-custom  border-gray-400"> 
                            <div class="mx-2 font-semibold">{{progra.prp_lugar}}</div>
                            <div class="my-0 flex align-items-end">
                                <a href="javascript:void(0)" class="btn btn-bg-light p-2 mr-2" v-tooltip.top="'Editar'" @click="addEditPractica_onClick(progra)" > 
                                    <i class="pi pi-pencil text-primary"></i>
                                </a>
                                <a href="javascript:void(0)" class="btn btn-bg-light-danger p-2" v-tooltip.top="'Eliminar'"   @click="deletePractica_onClick(progra)"> 
                                    <i class="pi pi-trash text-danger"></i>
                                </a>
                            </div>
                        </div>

                        <Divider type="dashed" class="p-1 m-0"/>
                    </div>
                    <div class="flex border-round bg-light-warning p-2 border-dashed-custom  border-warning w-full" v-if="practicaList.length == 0"> 
                        <div class="block ">
                            <a href="javascript:void(0)" class="font-bold text-primary"> Detalle de programación practicas.</a> 
                            No se ha establecido ninguna especificación de prácticas, aplica para las carreras que tienen como requisito prácticas profesionales
                        </div> 
                    </div>
                </div>
            </div>
            <!--Begin: Detalle de programacion de practicas -->
        </div>
    </div>

    <!--Begin: Create Planificación Semanal -->
    <Dialog v-model:visible="isDialogDetailBlock" :modal="true" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '640px'}"> 
        <template #header>
            <div class="flex align-items-center" >
                <div class="symbol symbol-40 symbol-circle symbol-light-danger align-content-center mr-2" v-if="modalCommon.type != MODAL.SEMANAO">
                    <span class="symbol-label">{{ isEditBloqueDetalle ? bloqDetalleIndex + 1 :  TotalBloque + 1}}</span>
                </div>
                <div class="flex-grow-1">
                    <h5 class="font-semibold text-gray-900 m-0">PLANIFICACIÓN SEMANAL</h5> 
                    <small class="text-muted font-semibold" v-if="modalCommon.type == MODAL.SEMANAO">Reorganizar semanas</small>
                </div>
            </div>
        </template>
        <!--Begin: Dynamic Content -->
            <keep-alive>
                <CreateBloqueDetalle :BloqueDetalle="selectedBloqueDetalle" :service="planDidacticoService" :Ejes="selectedEjeList" @save_onEmit="saveDetalle_onClick" v-if="modalCommon.type == MODAL.SEMANAC" @cancel_onEmit="isDialogDetailBlock =  false"/>
            </keep-alive>
            <ReOrderBloqueDetalle :BloqueDetalleList="planBloqueDetalleList" :service="planDidacticoService" @apply_onEmit="reOrder_onClick()" v-if="modalCommon.type == MODAL.SEMANAO"  @cancel_onEmit="isDialogDetailBlock =  false"/>
        <!--End: Dynamic Content -->
    </Dialog> 
    <!--End: Create Planificación Semanal -->
  
    <!--Begin: Common Modal -->
    <Dialog v-model:visible="isModalCommonVisible" :modal="true" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '640px'}">
        <template #header>
            <div class="mb-1 flex align-items-center ">
                <div class="flex-grow-1">
                    <h5 class="font-semibold text-gray-900 m-0">{{modalCommon.title.toUpperCase()}} </h5>
                    <small class="text-muted font-semibold">{{modalCommon.subTitle}}</small>
                </div>
            </div>
        </template>
       
        <UpsertBloqueEje :Service="planDidacticoService"  :Semana ="selectedBloqueDetalle" :Eje="selectedDetalleEje" @success_onEmit="successEje_onSubmit" @cancel_onEmit="isModalCommonVisible =  false" v-if="modalCommon.type == MODAL.EJE"/>
        <UpsertBloqueNivel :Service="planDidacticoService" :Nivel="selectedBloqueNivel" @success_onEmit="successNivel_onSubmit" @cancel_onEmit="isModalCommonVisible =  false" v-if="modalCommon.type == MODAL.NIVEL"/>
        <UpsertPractica :Service="planDidacticoService" :Practica="selectedPractica"  @success_onEmit="successPractica_onClick" @cancel_onEmit="isModalCommonVisible =  false" v-if="modalCommon.type == MODAL.PRACTICA"/>
    </Dialog> 
    <!--Begin: Common Modal -->

</template>


<script>

import moment from 'moment'
import { AngleDouble, Message, Edit, Pixel, Compiling } from '@/components/svg/_svgHelper.js'

import PlanDidacticoService from '@/service/planDidacticoService'
import UpsertBloque from './UpsertBloque.vue'
import CreateBloqueDetalle from './CreateBloqueDetalle.vue'
import UpsertPractica from './UpsertPractica.vue'
import UpsertBloqueEje from './UpsertBloqueEje'
import UpsertBloqueNivel from './UpsertBloqueNivel'
import ReOrderBloqueDetalle from './ReOrderBloqueDetalle'

export default {
    planDidacticoService :  null,
    emits:["update:BloqueList", "update:Index"],
    props:{
        Index :{
            type: Number,
            default : -1
        },
        BloqueList : {
            type: Array,
            default(){
                return []
            }
        },
        Codigo :{
            type: Number,
            default : 0
        }, //Codigo Plan
        Competencia:{
            type: String,
            default : ""
        },
        Comision:{
            type: Array,
            default(){
                return []
            }
        },
        Asignatura: {
            type: Object,
            default() {
                return {}
            }
        },
        AllowEdit : {
            type : Boolean,
            default: true
        }
    },
   
    data(){
        return{
            
            planBloqueList : [],
            selectedPlanBloque:{
                plb_codigo : 0,
                plb_nombre_tec : "",
                plb_nombre_est: "",
                plb_horas_semanal : 0,
                plb_criterio_eval: "",
                plb_evidencia_logro: "",
                plb_instrumento_eval: "",
                plb_codpld: this.Codigo,
               
            },
            planBloqueDetalleList: {},
            selectedBloqueDetalle :{
                pbd_codigo : 0,
                pbd_semana : 1,
                pbd_fecha_desde: new Date(),
                pbd_fecha_hasta: new Date(),
                pbd_codplb : this.Codigo,
                pbd_niveles :  false

            },

            bloqueDetalleEjeList : [], //Detalle List
            selectedDetalleEje : {}, //Bloque Eje seleccionado
            selectedEjeList : [], // Ejes Aplicados
            

            practicaList:[],
            selectedPractica : {
                prp_codigo : 0,
                prp_total_horas : 0 ,
                prp_nombre : "",
                prp_lugar : "",
                prp_codemp: 0,
                prp_codpld : this.Codigo
            },

            bloqueNivelList : [],
            selectedBloqueNivel: {},

            //Logical Control
            isCreateBloque  : false,
            isExistsBloque : false,
            isEditBloqueDetalle :false,
            isDialogDetailBlock : false,
            isModalCommonVisible :  false,

            modalCommon : {
                title :  "",
                subTitle  : "",
                type : null
            },
            MODAL : {
                EJE : "eje",
                NIVEL : "Nivel",
                PRACTICA : "Practica",
                SEMANAC  : "Semana Create",
                SEMANAO  : "Semana Organizar"
            },

            //Index Control
            bloqIndex: -1,//Bloques
            bloqDetalleIndex: -1,//Semana
            
            itemsButtonBloque : [{
                label:'Eliminar Bloque',
                icon:'pi pi-pw pi-times text-danger',
                    command: () => {
                    if(!this.canEdit()) return;
                    this.$confirm.require({
                        message: `¿Está seguro de Eliminar este Bloque: B${this.bloqIndex+ 1}?`,
                        header: 'Confirmación',
                        icon: 'pi pi-exclamation-triangle text-yellow-600',
                        acceptClass: 'p-button-danger',
                        rejectLabel :  "Cancelar",
                        acceptLabel :  "Sí, Eliminar",
                        accept: async() => {
                            try{
                                var message = ""
                                await this.planDidacticoService.deleteBloque(this.Codigo , this.selectedPlanBloque.plb_codigo).then(res  => message = res)
                                await this.fx_getBloques()
                                this.$toast.add({severity:'info', summary:'Eliminado', detail:message, life: 3000});
                            }catch(ex){
                                if(ex.response.data){
                                    this.$toast.add({severity:'error', summary:'Cancelado', detail:ex.response.data, life: 3000});  
                                }
                                this.$catchError(ex);
                            }
                        }
                    })
                }
            }],
            itemsButtonDetalle:[
                /*{
                    label: 'Organizar',
                    icon: 'pi pi-pw pi-angle-double-up text-primary',
                    command: () => {
                        if(this.bloqDetalleIndex <= -1){
                            this.$toast.add({severity:'warn', summary:'Semana', detail:'Semana no válida o no existe', life: 3000});
                            return;
                        }
                        if(!this.canEdit()) return;
                        this.isDialogDetailBlock =  true;
                        this.modalCommon.type =  this.MODAL.SEMANAO;
                    }
                },*/
                {
                    label:'Eliminar',
                    icon:'pi pi-pw pi-times text-danger',
                     command: () => {
                        if(this.bloqDetalleIndex <= -1){
                            this.$toast.add({severity:'warn', summary:'Semana', detail:'Semana no válida o no existe', life: 3000});
                            return;
                        }
                        if(!this.canEdit()) return;
                        this.$confirm.require({
                            message: `¿Está seguro de Eliminar esta semana : Semana ${this.selectedBloqueDetalle.pbd_semana}?`,
                            header: 'Confirmación',
                            icon: 'pi pi-exclamation-triangle text-yellow-600',
                            acceptClass: 'p-button-danger',
                            rejectLabel :  "Cancelar",
                            acceptLabel :  "Sí, Eliminar",
                            accept: async() => {
                                try{
                                    var message = ""
                                    await this.planDidacticoService.deleteBloqueDetalle(this.selectedPlanBloque.plb_codigo,this.selectedBloqueDetalle.pbd_codigo).then(res  => message = res)
                                    await this.fx_getBloqueDetalle()
                                    this.$toast.add({severity:'info', summary:'Eliminado', detail:message, life: 3000});
                                }catch(ex){
                                    if(ex.response.data){
                                        this.$toast.add({severity:'error', summary:'Cancelado', detail:ex.response.data, life: 3000});  
                                    }
                                    this.$catchError(ex);
                                }
                            }
                        })
                    }
                },
            ]
        }
    },
    created(){
        this.planDidacticoService =  new PlanDidacticoService(this.axios)
    },

    async mounted(){
        await this.fx_getBloques();
        await this.fx_getPracticas()
    },
    methods:{
        

        async fx_getPracticas(){
            await this.planDidacticoService.getPracticas(this.Codigo).then(res => this.practicaList = res);
        },

        async fx_getBloqueNivel(){
            await this.planDidacticoService.getBloqueNivel(this.selectedPlanBloque.plb_codigo).then(res=> this.bloqueNivelList =  res);
        },
        
        //Get Semanas
        async fx_getBloqueDetalle(index = 0){
            this.planBloqueDetalleList = [];
            this.bloqDetalleIndex = -1
            await this.planDidacticoService.getBloqueDetalle(this.selectedPlanBloque.plb_codigo).then(res=> this.planBloqueDetalleList = res)
            if(this.planBloqueDetalleList){
                this.bloqDetalleIndex = index > 0 ? this.planBloqueDetalleList.findIndex(x=> x.pbd_semana == index) : index
                this.selectedBloqueDetalle = this.planBloqueDetalleList[this.bloqDetalleIndex];
                this.fx_getBloqueEjes(0);
            }else{
                this.planBloqueDetalleList = []
            }
        },

        async fx_getBloques(){
            await this.planDidacticoService.getBloques(this.Codigo).then(res => this.planBloqueList = res);
             if(this.planBloqueList){
                 this.isExistsBloque =  true;
                 this.bloqIndex = 0
                 this.selectedPlanBloque = this.planBloqueList[this.bloqIndex];
                 this.fx_getBloqueDetalle();
                 this.fx_getBloqueNivel();
             }else{
                this.planBloqueList = []
                this.selectedPlanBloque = {}
                this.bloqIndex = -1
                this.isExistsBloque =  false;
             }

            this.$emit("update:BloqueList", this.planBloqueList)
            this.$emit("update:Index", this.bloqIndex)
        },
        async fx_getBloqueEjes(codEje){
            this.bloqueDetalleEjeList = [];
            await this.planDidacticoService.getBloqueDetalleEje(this.selectedBloqueDetalle.pbd_codigo,codEje).then(res => this.bloqueDetalleEjeList = res); 
            if(!this.bloqueDetalleEjeList){
                this.bloqueDetalleEjeList = [];
            }
        },


    //#region ***Begin: Bloque
        async createNewBloque_onClick(){
            if(!this.canEdit()) return;
            var newElement  = {
                plb_codigo : 0,
                plb_nombre_tec : "",
                plb_nombre_est: "",
                plb_horas_semanal : 0,
                plb_criterio_eval: "",
                plb_evidencia_logro: "",
                plb_instrumento_eval: "",
                plb_codpld: this.Codigo
            }

           if(this.planBloqueList.length >=3){
               this.$confirm.require({
                message: '¿Ya existen 3 bloques desea agregar un nuevo?',
                header: 'Confirmación',
                icon: 'pi pi-exclamation-triangle text-yellow-600',
                accept: async() => {
                    this.selectedPlanBloque  =  {...newElement}
                    this.isCreateBloque =  true
                }
            });
           }else{
                this.selectedPlanBloque  =  {...newElement}
                this.isCreateBloque =  true
           }
           
           
            
        },
        editBloque_onClick(){
            if(!this.canEdit()) return;
            this.isCreateBloque =  true
        },
        async saveBloque_onClick(){ //For Emit
            this.isCreateBloque =  false;
            await this.fx_getBloques();
        },
        cancelBloque_onClick(){ //For Emit
            this.isCreateBloque = false
            if(this.bloqIndex >= 0){
                this.selectedPlanBloque = this.planBloqueList[this.bloqIndex];
            }
        },
        async viewBloque_onClick(data, index){
            this.bloqIndex = index;
            this.selectedPlanBloque =  data;

            this.planBloqueDetalleList = [];
            this.bloqueDetalleEjeList = [];
            
            this.bloqueNivelList = []
            await this.fx_getBloqueDetalle();
            this.$emit("update:Index", this.bloqIndex)
            return this.bloqIndex;
        },
    //#endregion ***End: Bloque

    //#region Begin: Practicas profesionales
        addEditPractica_onClick(data = null){ 
            if(!this.canEdit()) return;
            if(!data){ //New
                this.selectedPractica = {
                    prp_codigo : 0,
                    prp_total_horas : 0 ,
                    prp_nombre : "",
                    prp_lugar : "",
                    prp_codemp: 0,
                    emp_nombres_apellidos: "",
                    prp_codpld : this.Codigo
                }
            }else {
                this.selectedPractica =  {... data}
            }
            this.modalCommon.title =  "PRACTICAS DEL PLAN ",
            this.modalCommon.subTitle = "(aplica para las carreras que tienen como requisito prácticas profesionales)"
            this.modalCommon.type  =  this.MODAL.PRACTICA
            this.isModalCommonVisible =  true;
        }, 

        successPractica_onClick(){ //for emit
            this.fx_getPracticas()
            this.isModalCommonVisible =  false;
        },

        async deletePractica_onClick(data){ 
            if(!this.canEdit()) return;
            this.$confirm.require({
                message: '¿Está seguro de eliminar este registro?',
                header: 'Confirmación',
                icon: 'pi pi-exclamation-triangle text-yellow-600',
                acceptLabel : "Sí, Eliminar",
                acceptClass : "p-button-danger",
                accept: async() => {
                    try{
                        var message = ""
                        await this.planDidacticoService.deletePractica(data.prp_codigo).then(res  => message = res)
                        await this.fx_getPracticas()
                        this.$toast.add({severity:'info', summary:'Eliminado', detail:message, life: 3000});
                    }catch(ex){
                        if(ex.response.data){
                            this.$toast.add({severity:'error', summary:'Cancelado', detail:ex.response.data, life: 3000});  
                        }
                        this.$catchError(ex);
                    }
                }
            })
        },
    //#endregion End: Practicas profesionales
    
    //#region **** Begin: Bloque Detalle ****
        async editeBloqueDetalle_onClick(editMode = false){
            if(!this.canEdit()) return;
            this.isEditBloqueDetalle =  editMode;
            this.selectedEjeList = [] 
            this.modalCommon.type =  this.MODAL.SEMANAC;

            if(!editMode){
               
                this.selectedBloqueDetalle = {
                    pbd_codigo : 0,
                    pbd_semana : this.planBloqueDetalleList.length  + 1,
                    pbd_fecha_desde: new Date(),
                    pbd_fecha_hasta: new Date(),
                    pbd_codplb : this.selectedPlanBloque.plb_codigo,
                    pbd_niveles :  false
                }
            }

            else{
                this.selectedBloqueDetalle = {...this.planBloqueDetalleList[this.bloqDetalleIndex]};
                this.selectedBloqueDetalle.pbd_fecha_hasta = new Date(this.selectedBloqueDetalle.pbd_fecha_hasta)
                this.selectedBloqueDetalle.pbd_fecha_desde = new Date(this.selectedBloqueDetalle.pbd_fecha_desde)
                
                this.bloqueDetalleEjeList.map((object) =>{
                    this.selectedEjeList.push( {
                        epl_codigo: object.ejb_codepl,
                        epl_nombre: object.epl_nombre,
                        epl_descripcion: null
                    })
                });
            }
            this.isDialogDetailBlock =  true

        },
        saveDetalle_onClick(evt){ //for emit
            this.isDialogDetailBlock =  false;
            this.fx_getBloqueDetalle(evt.pbd_semana);

            if(evt.pbd_niveles){
                this.fx_getBloqueNivel();
            }
        },

        reOrder_onClick(){ //For Emit
            this.isDialogDetailBlock =  false;
            this.fx_getBloqueDetalle();
        },

        tabDetalle_onChange(evt){ //Tab de Programacion Semanal
            this.bloqDetalleIndex = evt.index;
            var result =  this.planBloqueDetalleList[evt.index];
             this.selectedBloqueDetalle =  result
            if(result.pbd_niveles){ //Mostrar niveles
                this.fx_getBloqueNivel();
                return;
            }
          
           
            this.fx_getBloqueEjes(0);
        },

        editDetalle_onClick(value, modal){
            switch(modal){
                case "modalEje":
                    this.selectedDetalleEje =  {...value}
                    this.modalCommon.title =  this.selectedDetalleEje.epl_nombre,
                    this.modalCommon.subTitle = "Eje de Planificación"
                    this.modalCommon.type  =  this.MODAL.EJE
                    break;
                case "modalNivel":
                    this.selectedBloqueNivel =  {...value}
                    this.modalCommon.title =  this.selectedBloqueNivel.nid_nombre,
                    this.modalCommon.subTitle = "Nivel de dominio",
                     this.modalCommon.type  =  this.MODAL.NIVEL
                    break;
                default:
                    break;
            }


            if(!this.canEdit()) return;
             this.isModalCommonVisible  =  true 
        },
    //#endregion **** End: Bloque Detalle ****

    //#region ***Begin: Ejes Semanal 
        async successEje_onSubmit(){ // for emit
            this.isModalCommonVisible = false;
            this.fx_getBloqueEjes(0);
        }, 
        async successNivel_onSubmit(){ //for emit
            this.isModalCommonVisible = false;
            this.fx_getBloqueNivel();
        },
    //#endregion ***End: Ejes Semanal 

    //#region ***Begin: Styles
        DateFormat(data) {
            return moment(data).format('DD/MM/YYYY')
        },
        generatorColor(idx){
            var styleList =  [
                {color : "#3F4254" , background : "#F3F6F9" },
                {color : "#1BC5BD" , background : "#C9F7F5" },
                {color : "#3699FF" , background : "#E1F0FF" },
                {color : "#F64E60" , background : "#FFE2E5" },
                {color : "#8950FC" , background : "#EEE5FF" },
                {color : "#FFA800" , background : "#FFF4DE" },
                {color : "#181C32" , background : "#D1D3E0" },
            ]

            var mycolor = styleList[idx+1]
            return "color : " + mycolor.color +'; background-color:' + mycolor.background;
        },
    //#endregion ***End: Styles

        canEdit(){
            if(!this.AllowEdit){
                this.$toast.add({severity:'warn', summary:'¡Autorización!', detail:"El documento no puede ser modificado. Verifique que no está marcado como 'validar' o 'aprobado'", life: 3000});
                return false;
            }
            return true;
        }   
    },

    computed:{
        TotalBloque(){
            return this.planBloqueDetalleList.length;
        },
        existsNiveles(){
            if(this.planBloqueDetalleList.length <= 0){
                return  true;
            }
            var exists =  this.planBloqueDetalleList.some(x=> x.pbd_niveles == true);
            return exists;
        }
    },
    components: { 
        AngleDoubleIco : AngleDouble, 
        EditIco : Edit,
        MessageIco : Message, 
        PixelIco : Pixel,
        CompilingIco : Compiling,
        UpsertBloque, 
        CreateBloqueDetalle, 
        UpsertPractica,
        UpsertBloqueEje,
        UpsertBloqueNivel,
        ReOrderBloqueDetalle
    },
    
}
</script>,
    


<style scoped>
.p-avatar.p-avatar-hover:hover {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 1;
}
.p-avatar.p-avatar-hover {
  cursor: pointer;
}
.border-solid{
    border: 1px solid var(--gray-300) !important;
}
.border-solid:hover{
    border: 1px solid  var(--primary-color) !important;
}

</style>