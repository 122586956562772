<template>
    <DataTable :value="areaFormacionList" :rowHover="true" stripedRows  
    v-model:selection="selectedArea"  responsiveLayout="scroll" selectionMode="multiple" 
    :metaKeySelection="false" dataKey="paf_codigo"> 
        <template #loading>
            Cargando áreas de formación. Espere por favor...
        </template>
        <template #empty>
            Ninguna áreas de formación encontrada.
        </template>
        <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
        <Column class="m-0 p-0">
            <template #header>
                <div class="flex  flex-grow-1 justify-content-between">
                    <div>Área</div>
                    <button class="p-link mr-2" @click="isDialogPlan = true" v-tooltip.top="'Nuevo'">
                        <span class="pi pi-plus-circle text-primary"></span>
                    </button>
                </div>
            </template>
            <template #body="slotProps">
                <!-- <div class="flex flex-column">
                    <div class="flex align-items-center">
                        <div class="flex flex-column flex-grow-1">
                            <a href="javascript:void(0)" class="text-gray-700 hover:text-primary mb-1 font-bold text-normal">{{slotProps.data.paf_area_form}}</a>
                            <div class="flex justify-content-between align-items-center">
                                <span class="text-muted font-bold text-xs">{{Descriptor}} </span>
                                <span class="label label-sm label-light-danger label-inline font-semibold ml-1" >{{PlanAcademico.pla_nombre}}</span>
                            </div>
                            
                        </div>
                    </div>
                    <div class="pt-2">
                        <p class="text-gray-900 font-normal mb-2">{{slotProps.data.paf_competencia}}</p>
                    </div>
                </div> -->
                 <Panel :header="slotProps.data.paf_area_form" :toggleable="true" :collapsed="true" class="m-0 p-0 hover:bg-light-primary"  style="background-color:none">
                    <p class="text-justify">{{slotProps.data.paf_competencia}}</p>
                   
                </Panel>
        
            </template>
        </Column>
    </DataTable>

    <!--Begin: Dialgo-Edit Plan -->
    <Dialog  v-model:visible="isDialogPlan" :modal="true"  class="p-fluid" :style="{width: '480px'}">
        <template #header>
            <h5>Editar Plan Académico</h5>
        </template>
        <UpsertPlan :Service="facultadService" :PlanAcademico="PlanAcademico"/>
    </Dialog>
    <!--End: Dialog-Edit Plan -->
</template>

<script>
import FacultadService from '@/service/facultadService'
import UpsertPlan from '@/components/Generales/UpsertPlanAcademico.vue'

export default {
    facultadService : null,
    emits: ["update:SelectedItems"],
    props: {
        Descriptor :{
            type : String,
            default :  ""
        },

        SelectedItems : {
            type :  Array,
            default(){
                return []
            }
        },
        
        PlanAcademico : {
            type: Object,
            default(){
                return {}
            }
        }
    },
    data(){
        return{
            selectedArea : this.SelectedItems,
            areaFormacionList : [],
            isDialogPlan :  false
        }
    },
    created(){
        this.facultadService  = new FacultadService(this.axios);
    },
    async mounted(){
        await this.facultadService.getAreaFormacion(this.PlanAcademico.pla_codigo).then(res => this.areaFormacionList = res)
       
    },
    methods:{

    },
    watch:{
        // eslint-disable-next-line no-unused-vars
        selectedArea: function(val){
           this.$emit('update:SelectedItems', val)
        }
    },
    components:{
        UpsertPlan
    }
}
</script>
<style scoped>
    .p-panel-header{
        background:none !important
    }
</style>
