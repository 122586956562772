<template>
    <div>
        <Dialog v-model:visible="isVisible" :breakpoints="{'960px': '95vw'}" :style="{width: '720px'}" :class="classStyle"  class="shadow-8 border-1 border-primary" @hide="$emit('hide');"> 
            <template #header>
                <div class="flex flex-column w-full">
                    <div class="flex flex-column  md:flex-row align-items-start flex-grow-1 h-full">
                        <div class="flex flex-1 p-4 mx-1 border-round-top bg-gray-100 w-full h-full sm:my-0" :class="{'currentTab ' : (currentTab == 1) }">
                            <div class="flex mx-1 ">
                                <span class="label label-lg  label-inline font-semibold text-lg numberTab" style="height:2.25rem;width:2.25rem">1</span>
                            </div>
                            <div class="flex flex-column">
                                <span class="text-lg font-bold titleTab">Información</span>
                                <span class="text-sm">Plan Didáctico</span>
                            </div>
                        </div>
                        <div class="flex flex-1 p-4 mx-1 border-round-top bg-gray-100 w-full sm:my-0 h-full" :class="{'currentTab ' : (currentTab == 2) }">
                            <div class="flex mx-1">
                                <span class="label label-lg  label-inline font-semibold text-lg numberTab" style="height:2.25rem;width:2.25rem">2</span>
                            </div>
                            <div class="flex flex-column">
                                <span class="text-lg font-bold titleTab" >Revisión</span>
                                <span class="text-sm">Lista Evaluación</span>
                            </div>
                        </div>
                        <div class="flex flex-1 p-4 mx-1 border-round-top bg-gray-100 w-full sm:my-0 h-full" :class="{'currentTab ' : (currentTab == 3) }">
                            <div class="flex mx-1">
                                <span class="label label-lg  label-inline font-semibold text-lg numberTab" style="height:2.25rem;width:2.25rem">3</span>
                            </div>
                            <div class="flex flex-column">
                                <span class="text-lg font-bold titleTab">Resumen</span>
                                <span class="text-sm">Retroalimentación</span>
                            </div>
                        </div>
                    </div>
                    <div class="h-3px w-100 p-0 m-0"  :class="statusColor"></div>
                </div>
            </template>
            <!--BEGIN - información Plan Didactico -->
            <section data-name="Informacion" class="mt-3" v-show="currentTab == 1"> 
                <div class="flex  flex-column justify-content-center">
                    <h5 class="text-center font-bold p-0 m-0">LISTA DE COTEJO DE REVISIÓN DE PLANIFICACIÓN DIDÁCTICA</h5>
                    <p class="text-center font-normal text-xs">Revisar los elementos de la planificación didáctica y su concordancia con el Modelo Educativo.</p>
                    
                    <div class="flex flex-column mt-2">
                        <div class="flex justify-content-end">
                            <div class="label label-lg label-inline font-semibold my-1" :class="statusLightColor">
                                <i class="pi pi-star inline-flex md:hidden"></i>
                                <span class="hidden md:inline-flex">{{statusText}} </span>
                            </div>
                        </div>
                        <div class="flex ">
                            <div class="h-100 p-0 mr-1" style="width:2px" :class="statusColor"></div>
                            <div class="flex flex-grow-1 flex-column justify-content-center">
                                    <span class="text-gray-700 font-bold text-xl text-primary uppercase">{{PlanDidactico.pld_asignatura}}</span>
                                    <span class="text-gray-600 font-semibold text-base">{{nombreCarrera}}</span>
                                    <span class="text-gray-600 text-sm">{{nombreFacultad}}</span>
                                    <span class="text-gray-600 text-xs">Rev{{selectedRevisionPlan.rpd_rev_num}}-{{PlanDidactico.pld_codigo}}</span>
                            </div>
                            <div class="mx-1">
                                <div class="flex justify-content-between align-items-center ">
                                    <span class="text-gray-700 font-bold mr-2">Presentación: </span>
                                    <a href="javascript:void(0)" class="text-muted hover:text-primary"> {{fx_DateFormat(selectedRevisionPlan.rpd_fecha_presenta)}}</a>
                                </div>
                                <div class="flex justify-content-between align-items-center my-1">
                                    <span class="text-gray-700 font-bold mr-2">Ciclo: </span>
                                    <a href="javascript:void(0)" class="text-muted hover:text-primary"> {{PlanDidactico.pld_nomcil}}</a>
                                </div>
                                <div class="flex justify-content-between align-items-center">
                                    <span class="text-gray-700 font-bold mr-2">Plan: </span>
                                    <a href="javascript:void(0)" class="text-muted hover:text-primary"> {{PlanDidactico.pld_plan_acad}}</a>
                                </div>
                                <div class="flex justify-content-between align-items-center my-1">
                                    <span class="text-gray-700 font-bold mr-2">Revisión: </span>
                                    <a href="javascript:void(0)" class="text-muted hover:text-primary"> {{fx_DateFormat(selectedRevisionPlan.rpd_fecha_revision)}}</a>
                                </div>
                            </div>
                        </div>
                        <Divider/>
                            <p class="text-center text-gray-900"> Esta lista de cotejo será completada por el Vicedecano (o Coordinador en el caso de Postgrado), indicando si se cumple o no cada uno de los elementos.</p>
                            <p class="text-center text-gray-900"> Tome en cuenta que, <b>SI</b> el indicador se cumple parcialmente, es recomendable marcar <b>NO</b> y en el comentario colocar la explicación que corresponda.</p>
                        
                            <div class="flex w-full justify-content-center border-round py-2 px-4 border-dashed-custom "  :class="(Object.keys(selectedRevisionPlan).length === 0  ? 'bg-light-danger border-danger' : 'bg-light-primary border-primary' )" > 
                                <div class="block text-center ">
                                    <div class="ml-1" v-if="selectedRevisionPlan.rpd_codigo == undefined ">
                                        <ProgressSpinner style="width:50px;height:50px" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"  v-if="submittedGenerate"/>
                                        <a href="javascript:void(0)" class="font-bold ml-1 text-danger" @click="generateRevision_onClick()" v-else> Generar Revisión</a>
                                    </div>
                                    <div class="ml-1" v-else>
                                        <ProgressSpinner style="width:50px;height:50px" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"  v-if="submittedGenerate"/>
                                        <a href="javascript:void(0)" class="font-bold ml-1 text-primary" @click="printRevision_onClick()" v-else> Ver Resultados</a>
                                    </div>
                                </div> 
                            </div>
                        
                        
                        <Divider/>
                        <div class="grid">
                            <div class="col-12  md:col-6" v-for="com,index in ComisionList" :key="index">
                                <div class="flex align-items-start">
                                    <div class="mr-2" >
                                        <div class="symbol symbol-40 symbol-circle shadow-4" :class="'symbol-light-'+ (com.coe_estado === 'E' ? 'danger' : 'primary')">
                                            <span class="symbol-label font-bold  text-base">{{com.emp_nombres_apellidos[0].toUpperCase()}}</span>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1">
                                        <a href="javascript:void(0)" class="text-gray-900 font-medium  hover:text-primary text-base">{{com.emp_nombres_apellidos}}</a>
                                        <span class="text-gray-500 font-medium block text-sm"> {{com.coe_puesto}}</span>
                                        <span class="text-gray-500 block uppercase text-xs"> {{com.emp_sede}}</span>
                                    </div>
                                </div>
                                <Divider type="dashed" class="my-0 py-0"/>
                            </div>
                        </div> 
                    </div>
                </div> 
            </section>            

            <!--BEGIN - Lista de Cotejo de Revisión -->
            <section data-name="Revision" class="mt-3" v-show="currentTab == 2" >
                <div class="flex flex-column align-items-center flex-grow-1 mt-2">
                    <span class="sm:text-lg md:text-2xl uppercase text-center">{{currentCriterio.rse_nombre}}</span>
                    <div class="flex flex-column align-items-center w-8 " style="float:right">
                        <ProgressBar :value="completeIndicator" class="mx-3 flex-shrink-1"  :showValue="false" style="height:0.8rem;width: 95%;" />
                        <span class="font-semibold text-sm md:text-base  text-gray-600">Revisión {{completeIndicator.toFixed(2)}}% completado</span>
                    </div>
                </div>
                <!-- Begin: Pagination -->
                <div class="flex justify-content-center">
                    <div class="p-paginator p-component">
                        <button class="p-paginator-first p-paginator-element p-link" style="border:none" :class="{'p-disabled': paginator.page == 1 }" @click="onPage(1)">
                            <span class="p-paginator-icon pi pi-angle-double-left"></span><span class="p-ink"></span>
                        </button>
                        <button class="p-paginator-prev p-paginator-element p-link"  style="border:none" :class="{'p-disabled': paginator.page == 1}" @click="onPage(paginator.page -1)">
                            <span class="p-paginator-icon pi pi-angle-left"></span><span class="p-ink"></span>
                        </button>

                        <!-- Page Number Criterio -->
                        <div class="p-paginator-pages">
                            <template v-for="pageIndex in paginator.pageCount" :key="pageIndex">
                                <button class="p-paginator-page p-paginator-element p-link mr-1"  style="border-radius:25px; min-width:2rem; height:2rem"
                                :class="[{'p-highlight': paginator.page == pageIndex }, {'numberTab font-bold':(revDetallePlanList.find(x=> x.rce_codigo == criterioList[pageIndex-1].rce_codigo && x.rdp_codplb == criterioList[pageIndex-1].plb_codigo))}]"
                                    v-if="pageIndex > (paginator.page - 5) && pageIndex  <= (paginator.page + 5)"  @click="onPage(pageIndex)">{{pageIndex}}
                                    <span class="p-ink"></span>
                                </button>
                            </template>
                        </div>
                        <button class="p-paginator-next p-paginator-element p-link" style="border:none" :class="{'p-disabled': paginator.page == paginator.pageCount}" @click="onPage(paginator.page  + 1)">
                            <span class="p-paginator-icon pi pi-angle-right"></span>
                            <span class="p-ink"></span>
                        </button>
                        <button class="p-paginator-last p-paginator-element p-link" style="border:none" :class="{'p-disabled': paginator.page == paginator.pageCount}" @click="onPage(paginator.pageCount)" >
                            <span class="p-paginator-icon pi " :class="[completeIndicator.toFixed(0) != 100 ? 'pi-angle-double-right' : 'pi-check text-success' ]" ></span>
                            <span class="p-ink"></span>
                        </button>
                    </div>
                </div>
                <!-- End: Pagination -->
                <div class="h-3px w-100 bg-light-primary p-0 m-0" ></div>
            
                <div class="mt-5 md:px-4 px-2">
                    <transition :duration="{enter:500, leave: 300}"
                        enter-active-class="animated zoomIn"
                        leave-active-class="animated zoomOut"
                        mode="out-in">
                            <div class="my-1">
                                <h5 class="titleContainer title">
                                    <a href="javascript:void(0)" class="label label-lg  label-light-danger label-inline font-bold ml-2 py-3" v-if="currentCriterio.num_bloque > 0"> Bloque {{currentCriterio.num_bloque}}</a>
                                    {{currentCriterio.rce_enunciado}}
                                </h5>
                                
                            
                                <!-- Begin: Option Controller -->
                                <div class="optionContainer"  v-if="selectedDetalle == undefined || editModeCriterio" >
                                    <div class="option  border-round  my-2 p-3  flex justify-content-center text-lg text-primary font-bold"
                                        @click="selectedEscala_onClick(escalaItem)" v-for="escalaItem in escalaCriterioList.filter(x=> x.rec_codrce === currentCriterio.rce_codigo)" :key="escalaItem"
                                        :class="( selectedEscala.rve_codigo === escalaItem.rve_codigo ? 'hover:bg-blue-300 hover:text-primary bg-blue-500 text-white ' : 'bg-gray-100 hover:bg-gray-300')"
                                    >{{escalaItem.ree_descripcion}}</div>

                                    <div class="p-error" v-if="submitted && Object.keys(this.selectedEscala).length === 0 ">* Seleccione escala</div>
                                    <div class="p-fluid" v-show="selectedEscala.rve_req_comentario">
                                        <div class="field">
                                            <label for="rev_comentario">Comentario  <small id="plm_area_form" class="p-error">*</small></label>
                                            <Textarea  ref="rev_comentario" id="rev_comentario" v-model.trim="comentarioEscala" rows="3" cols="20" :autoResize="true"  placeholder="Comentario de criterio" />
                                            <small class="p-error" v-if="submitted && !comentarioEscala.trim()">* Enlace o descripción requerido.</small>
                                            <div class="flex justify-content-end" :class="{'text-danger': (comentarioEscala.length > 2500)}">
                                                <small>{{comentarioEscala.length}}/2500</small>
                                            </div>
                                        
                                        </div>
                                    </div> 
                                    <div class="flex justify-content-end mt-5">
                                        <Button label="Guardar Criterio" icon="pi pi-save" class="p-button-info" @click="submitRevision_OnClick()" :loading="isLoadingSaveCriterio"/>
                                    </div>
                                </div>
                                <!-- End: Option Controller-->

                                <!-- Begin: Answer Result  -->
                                <div class="flex  flex-column border-round p-2 border-dashed-custom bg-light-primary  border-primary my-1 "  v-else>  
                                    <div class="flex flex-shrink-0 justify-content-center">
                                        <ClipboardCheckIco :size="'svg-icon-2tx'" class="mr-3" />
                                        <div class="flex justify-content-between align-items-center flex-wrap md:flex-nowrap">
                                            <div class=" font-semibold">
                                                <h5 class="text-gray-800 font-bold py-0 my-0" >{{selectedDetalle.ree_descripcion}}</h5>
                                                <div v-if="selectedDetalle.rdp_comentario" class="text-base text-gray-600 pr-2 text-danger ">{{selectedDetalle.rdp_comentario}} </div>
                                            </div>
                                        </div>
                                    </div> 
                                    <div class="flex flex-column my-1 " v-if="editMode">
                                        <Divider class="m-1 p-0" />
                                        <div class="flex justify-content-end">
                                            <a href="javascript:void(0)" class="p-2 mr-2 align-self-center text-danger "  v-tooltip.top="'Editar'"   @click="editCriterio_onClick()"> 
                                                <i class="pi pi-pencil mr-1 text-gray-600"></i> Editar Criterio
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <!-- Begin: Answer Result  -->
                            </div>
                    </transition>
                </div>
            </section>

            <!--Begin: Resumen -->
            <section data-name="Resumen" class="mt-3" v-show="currentTab == 3" > 
                <div class="grid">
                    <div class="col-12 md:col-6">
                        <div class="p-fluid">
                            <div class="field">
                                <label for="rev_logros">Logros  <small class="p-error">*</small></label>
                                <Textarea  ref="rev_logros" id="rev_logros" v-model.trim="selectedRevisionPlan.rpd_logro" rows="5"  :readonly="!editMode"
                                :autoResize="true"  placeholder="Describa de forma sintética, los logros identificados (aspectos positivos, fortalezas en la planificación, etc)" />
                                <div class="flex justify-content-end" :class="{'text-danger': (selectedRevisionPlan.rpd_logro.length > 2500)}">
                                    <small>{{selectedRevisionPlan.rpd_logro.length}}/2500</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-6">
                        <div class="p-fluid ">
                            <div class="field">
                                <label for="rev_mejoras">Aspectos a Mejorar  <small  class="p-error">*</small></label>
                                <Textarea  ref="rev_mejoras" id="rev_mejoras" v-model.trim="selectedRevisionPlan.rpd_mejora" rows="5"  :readonly="!editMode"
                                :autoResize="true"  placeholder="De manera resumida, relacione qué aspectos se deben mejorar en la planificación (los detalles ya van en los comentarios, al lado de cada indicador)" />
                                <div class="flex justify-content-end" :class="{'text-danger': (selectedRevisionPlan.rpd_mejora.length > 2500)}">
                                    <small>{{selectedRevisionPlan.rpd_mejora.length}}/2500</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid" :style="[ !editMode ? 'pointer-events: none;' : '']" >
                    <div class="col-12 md:col-6">
                        <a href="javascript:void(0)" :class="{'bg-light-primary' : selectedRevisionPlan.rpd_estado == 'S'}" class="flex  flex-column border-round hover:bg-blue-50 p-4  border-dashed-custom  border-primary m-1 "  @click="selectedRevisionPlan.rpd_estado = 'S'">  
                            <div class="flex">
                                <DoubleCheckIco :size="'svg-icon-2tx'" class="mr-3"/>
                                <div class="flex justify-content-between align-items-center  flex-wrap md:flex-nowrap" >
                                    <div class="mb-3 mb:mb-0 font-semibold">
                                        <h5 class="text-gray-800 font-bold py-0 my-0" >¡Finalizada! </h5>
                                        <div class="text-sm text-gray-600 pr-2 ">En consecuencia, la planificación presentada si se encuentra lista, y deberá ser presentada y explicada a los estudiantes al inicio de clases</div>
                                    </div>
                                </div>
                            </div> 
                        </a>
                    </div>
                    
                    <div class="col-12 md:col-6">
                        <a href="javascript:void(0)" :class="{'bg-light-primary' : selectedRevisionPlan.rpd_estado == 'N'}" class="flex  flex-column border-round hover:bg-blue-50 p-4  border-dashed-custom  border-danger m-1 "  @click="selectedRevisionPlan.rpd_estado = 'N'">  
                            <div class="flex">
                                <ErrorCircleIco :size="'svg-icon-2tx'" class="mr-3"/>
                                <div class="flex justify-content-between align-items-center  flex-wrap md:flex-nowrap" >
                                    <div class="mb-3 mb:mb-0 font-semibold">
                                        <h5 class="text-gray-800 font-bold py-0 my-0" >¡No Finalizada! </h5>
                                        <div class="text-sm text-gray-600 pr-2 "> La planificación será enviada al vicedecano quien la revisará hasta que toda la comisión la haya validado. </div>
                                    </div>
                                </div>
                            </div> 
                        </a>
                        <div class="flex mx-2" v-if="selectedRevisionPlan.rpd_estado == 'N'">
                            <div class="p-fluid">
                                <label for="icon " class="text-danger">* Siguiente Revisión</label>
                                <Calendar id="icon" v-model="selectedRevisionPlan.rpd_prox_fecha_revision" :showIcon="true" :manualInput="false" dateFormat="dd/mm/yy"/>
                            </div>
                        </div>
                    </div>
                </div>    
                
                <div class="flex">
                    <Button  label="Finalizar Revisión" v-if="editMode" class="w-full my-2"   @click="saveRevision_onClick()"/>
                </div>
            </section>
            <!--Begin: Resumen -->


            <template #footer v-if="selectedRevisionPlan.rpd_codigo > 0">
                <div class="flex justify-content-center mt-2">
                    <Button :label="'Ir a ' + tabination.previewName" icon="pi pi-angle-double-left"  class="p-button-danger p-button-text " @click="this.currentTab--" v-if="currentTab != 1"/>
                    <Button :label="'Ir a ' + tabination.nextName" icon="pi pi-angle-double-right" v-if="currentTab != 3" @click="currentTab++"/>
                </div>
            </template>
        </Dialog>


        <!--Begin: Dialogo Impresión -->
        <Dialog v-model:visible="isPrintDialog" :modal="true" class="p-dialog-maximized pb-2">
            <template #header>
                <div class="h-100 bg-primary p-0 mr-1" style="width:2px"></div>
            </template>
            <object :data="printElement" type="application/pdf"  scrolling="auto" class="w-full h-full">
                <embed :src="printElement" type="application/pdf" />
            </object>
        </Dialog> 
        <!--End: Dialogo Impresión -->
    </div>
</template>
<script>

import {DoubleCheck,ErrorCircle,ClipboardCheck } from '@/components/svg/_svgHelper.js'
import moment from 'moment'

const delay = ms => new Promise(res => setTimeout(res, ms));

export default {
    components:{
        "DoubleCheckIco": DoubleCheck,
        "ErrorCircleIco":ErrorCircle,
        "ClipboardCheckIco" : ClipboardCheck},
    emits:["hide"],
    props:{
        classStyle : {
            type : String,
            default : "c-element"
        },
        Visible :{
            type:Boolean,
            default: false
        },

        Service : null
    },
    data(){
        return{
            selectedRevisionPlan : {},
            revDetallePlanList : [],
            selectedDetalle : {},

            //Plan Didáctico información
            ComisionList : [],
            PlanDidactico: {},
            nombreCarrera :  "",
            nombreFacultad: "",


          
            //Criterio, Escala, Esquema.
            criterioList : [],
            criterioIndex : -1,
            escalaCriterioList : [],
            currentCriterio : {},
            selectedEscala : {},
            comentarioEscala : "",


            submitted :  false,
            submittedGenerate :  false,
            
            //Controls
            printElement : "",
            isPrintDialog : false,
            currentTab : 1,
            tabination : {
                previewName : "",
                nextName: "Revisión",
            }, // Tab paginación
            paginator:{
                page : 1,
                first: 0,
                rows : 1, 
                pageCount : {}
            },
            isVisible : this.Visible, //Dialog
            editModeCriterio: false,
            editMode :  true, //Revisión
            isLoadingSaveCriterio : false
        }
    },
    async created(){
       
    },
    mounted(){
    },
    methods:{
        async fx_Init(){
            var codPlan  = this.PlanDidactico.pld_codigo;
            this.criterioList = await this.Service.getRevisionCriterio(codPlan)
            this.escalaCriterioList = await this.Service.getRevisionEscala();

            this.criterioIndex = 0;
            this.currentCriterio =  this.criterioList[this.criterioIndex]
            
            //set paginator
            this.paginator = {
                page : 1,
                first: this.criterioList.length > 0 ? 0 : -1,
                rows : 1, 
                pageCount : this.criterioList.length
            }

            
        },

        async fx_revisionLoad(codigo){
            await this.Service.getRevision(codigo).then(res=> this.selectedRevisionPlan = res);
            this.selectedRevisionPlan.rpd_prox_fecha_revision = new Date(this.selectedRevisionPlan.rpd_prox_fecha_revision)
            await this.Service.getRevisionDetalle(codigo).then(res=> this.revDetallePlanList = res); 
            this.editMode =  this.selectedRevisionPlan.rpd_estado == null
            await this.fx_Init();              
        },

        async openDialog_onClick(planDidactico, comisionList, carrera, facultad,codigo){
            try{
                this.PlanDidactico = planDidactico
                this.ComisionList = comisionList
                this.nombreCarrera =  carrera
                this.nombreFacultad = facultad
                this.isVisible =  true;

                //init
                this.selectedRevisionPlan = { 
                    rpd_logro : "",
                    rpd_mejora : "",
                    rpd_fecha_presenta : this.PlanDidactico.pld_fecha_revision
                }
                this.editMode =  true
                this.currentTab = 1;
                if(codigo === 0){
                    return;
                }

                //Load Init Parameters

                await this.fx_revisionLoad(codigo);
            }catch(ex){
                this.$toast.add({severity:'error', summary:'¡Error!', detail:'¡Ha ocurrido un error! ' + ex, life: 5000});
                await delay(500)
                this.isVisible =  false;
            }
        },

        //#region Header Functions
        async generateRevision_onClick(){
            this.submittedGenerate =  true;

            try{
                this.selectedRevision =  {}
                await delay(2000)
                await this.Service.createRevision({
                    rpd_codpld  : this.PlanDidactico.pld_codigo,
                    rpd_codusr_creador : this.$store.getters.getUserId
                }).then(res=> this.selectedRevisionPlan = res);
                await this.Service.getRevisionDetalle(this.selectedRevisionPlan.rpd_codigo).then(res=> this.revDetallePlanList = res); 
               await this.fx_Init();

            }catch(ex){
                var msg = '¡Ha ocurrido un error! ' +  ex;
                if(ex.response)
                    msg =  ex.response.data
                this.$toast.add({severity:'error', summary:'¡Error!', detail: msg, life: 5000});
                this.isVisible =  false;
            }
            
            this.submittedGenerate =  false;
        },

        async printRevision_onClick(){
            try {
                this.submittedGenerate =  true;

                await this.Service.printRevision(this.selectedRevisionPlan.rpd_codigo).then(res  =>{
                    const url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/pdf'}));
                    this.printElement  = url + `#filename=${this.PlanDidactico.pld_asignatura}.pdf`
                })

                this.isPrintDialog =  true
            }catch(ex){
                this.$toast.add({severity:'info', summary:'¡Ocurrió un error!', detail:ex, life: 3000});
            }
            this.submittedGenerate =  false;
        },
        //#endregion Header Functions

        //#region Question Functions
        async selectedEscala_onClick(eData){
            var escalaCriterioIdx =  this.escalaCriterioList.findIndex(x=> x.rve_codigo === eData.rve_codigo)
            this.selectedEscala =  this.escalaCriterioList[escalaCriterioIdx]
            await delay(100)
            this.$refs.rev_comentario.$el.focus()
        },
       
        //Save criterio
        async submitRevision_OnClick(){
            try{
                this.submitted = true;
                this.isLoadingSaveCriterio  =  true;

                if(Object.keys(this.selectedEscala).length === 0)
                    return; //Verificar si la escala seleccionado es un objeto valido
                if(this.selectedEscala.rve_req_comentario && !this.comentarioEscala.trim()){
                    this.$refs.rev_comentario.$el.focus() 
                    return; // Si ha seleccionado no para comentarios-.
                }

                var codCriterioDetalle = 0; // Criterio guardado
                if(this.selectedDetalle !== undefined){
                    codCriterioDetalle =  this.selectedDetalle.rdp_codigo; 
                    var index =  this.revDetallePlanList.findIndex(x=> x.rdp_codigo  ==  codCriterioDetalle);
                    this.revDetallePlanList.splice(index,1)
                }

                var data = {
                    rdp_codrpd : this.selectedRevisionPlan.rpd_codigo,
                    rdp_codrve : this.selectedEscala.rve_codigo,
                    rdp_codplb : this.currentCriterio.plb_codigo,
                    rdp_comentario : this.comentarioEscala.trim(),
                    rdp_codigo : codCriterioDetalle,
                    rce_codigo : this.currentCriterio.rce_codigo,
                    ree_codigo : this.selectedEscala.ree_codigo,
                    ree_descripcion : this.selectedEscala.ree_descripcion
                };
                await this.Service.upsertRevision(this.selectedRevisionPlan.rpd_codigo, data)
                .then(res => data.rdp_codigo =  res.rdp_codigo);
                this.revDetallePlanList.push(data)  //add data inserted to local
                

               
                if(this.criterioIndex == this.criterioList.length -1){
                    if(this.currentTab < 3) this.currentTab++
                    else this.currentTab = 1
                }else{
                    this.nextQuestion_onClick();
                }

                this.comentarioEscala =  "";
                this.submitted = false;
                this.editModeCriterio = false;
            }catch(ex){
                this.$toast.add({severity:'info', summary:'¡Ocurrió un error!', detail:ex, life: 3000});
            }finally{
               this.isLoadingSaveCriterio  =  false;
            }

            
        },
        
        editCriterio_onClick(){
            this.editModeCriterio =  true;
            this.selectedEscala =  this.escalaCriterioList.find(x=> x.rve_codigo == this.selectedDetalle.rdp_codrve);
            this.comentarioEscala =  this.selectedDetalle.rdp_comentario
        },

        previewQuestion_onClick(){
            if(this.criterioIndex != 0)
                this.criterioIndex--
            this.currentCriterio =  this.criterioList[this.criterioIndex]
            this.comentarioEscala =  "";
        },
        nextQuestion_onClick(){
            this.selectedEscala = {}
            if(this.criterioIndex != this.criterioList.length -1)
                this.criterioIndex++
            this.currentCriterio =  this.criterioList[this.criterioIndex]
            this.comentarioEscala =  "";
        },

        //Save Revision Final
        async saveRevision_onClick(){
            try{

                if(this.completeIndicator.toFixed(0) != 100){
                    throw "La revisión no ha sido completada."
                }else if (!this.selectedRevisionPlan.rpd_mejora.trim() && !this.selectedRevisionPlan.rpd_logro.trim())
                {
                    throw "Comentarios acerca del plan no ingresados. Mejoras/Logros"
                }else if (!this.selectedRevisionPlan.rpd_estado){
                    throw "No ha marcado un estado de revisión."
                }else if(this.selectedRevisionPlan.rpd_estado == "N"){
                    const dateIsBefore = moment(this.selectedRevisionPlan.rpd_prox_fecha_revision).isBefore(moment());  
                    if(dateIsBefore) throw "Especifique una fecha próxima de revisión válida."
                }

                this.selectedRevisionPlan.rpd_codusr_revision = this.$store.getters.getUserId
                await this.Service.updateRevision(this.selectedRevisionPlan).then(res=> this.selectedRevisionPlan =  res);
                this.$toast.add({severity:'info', summary:'¡Éxito!', detail: "Plan finalizado correctamente.", life: 5000});
                await this.fx_revisionLoad(this.selectedRevisionPlan.rpd_codigo);
                this.currentTab = 1; // information.

            }catch(ex){
                var msg =  ex;
                if(ex.response)
                    msg =  ex.response.data
                 this.$toast.add({severity:'error', summary:'¡Error!', detail: msg, life: 5000});
            }
        },
        // eslint-disable-next-line no-unused-vars
        async onPage(newIndex) {
            //event.page: New page number
            //event.first: Index of first record
            //event.rows: Number of rows to display in new page
            //event.pageCount: Total number of pages
            this.criterioIndex =  newIndex - 1 ;
            this.editModeCriterio = false;
            this.currentCriterio =  this.criterioList[this.criterioIndex]
        },

        fx_DateFormat(data) {
            if (data == undefined) return "N/R"
            var startDate = new Date(2020, 1, 1)
            var dateData = new Date(data);

            if(data === undefined)
                return ""
            if(dateData < startDate)
                return "N/R"
            return moment(data).format('DD/MM/YYYY')
        },
    },

    watch: {
        // eslint-disable-next-line no-unused-vars
        currentCriterio: function(newValue,oldValue){
            this.paginator.page =  this.criterioIndex + 1 ;
            if (Object.keys(newValue).length > 0)
                this.selectedDetalle =  this.revDetallePlanList.find(x=> x.rce_codigo == this.currentCriterio.rce_codigo && x.rdp_codplb == this.currentCriterio.plb_codigo)
        },
        // eslint-disable-next-line no-unused-vars
        currentTab:function(newValue, oldValue){
            var tabs = ["Información", "Revisión", "Resumen"];

            if(newValue < 3) this.tabination.nextName = tabs[newValue]           
            if(oldValue >= 1) this.tabination.previewName = tabs[newValue - 2]
        }
    },

    computed : {
        completeIndicator : function(){
            var totalCriterio =  this.criterioList.length;

            if(totalCriterio == 0) return 0;
            var percentValue = ( this.revDetallePlanList.length / totalCriterio ) * 100
            return percentValue;
        },

        statusColor(){
            if(this.selectedRevisionPlan.rpd_estado == "S") return "bg-primary";
            else if(this.selectedRevisionPlan.rpd_estado == "N") return "bg-danger"
            else  return "bg-warning";
        },
        statusLightColor(){
            if(this.selectedRevisionPlan.rpd_estado == "S") return "label-light-primary";
            else if(this.selectedRevisionPlan.rpd_estado == "N") return "label-light-danger"
            else  return "label-light-warning";
        },

        statusText(){
            if(this.selectedRevisionPlan.rpd_estado == "S") return "Finalizado";
            else if(this.selectedRevisionPlan.rpd_estado == "N") return "No Finalizado"
            else  return "En Revisión";
        }
    }
}
</script>

 <style lang="scss" >
 
    .c-element .p-dialog-header, .c-element .p-dialog-footer{
        padding-left: 1px;
        padding-top: 5px !important;
        padding-bottom: 0px;
        
        align-items: start !important;
        &.p-dialog-footer{
            padding: 20px;
            border-top: 1px solid var(--gray-400) !important;
        }

        &.p-dialog-header-icons{
            background: var(--gray-100) !important;
            padding: 2px !important;
        }
    }

    .optionContainer{
        .option {
            transition: 0.3s;
            cursor: pointer;
            border-radius: 25px !important;
            &:active {
                transform: scaleX(0.9);
            }
        }
    }

    .numberTab {
        background-color: var(--blue-100) !important;
        color: var(--blue-500)
    }
    .currentTab{
        background-color:#fff !important;

        .numberTab{
            background-color: var(--primary-color) !important;
            color: #fff
        }
        .titleTab{
            color: var(--primary-color)
        }
    }


  
</style>