<template>
    <OrderList v-model="bloqueDetalleList" dataKey="pbd_codigo">
        <template #header>
            Semanas de Bloque
        </template>
        <template #item="slotProps">
            <div class="flex align-items-center ">
                <div class="mr-2 flex align-items-center ">
                    <div class="symbol symbol-40 symbol-circle symbol-light-danger">
                        <span class="symbol-label">{{slotProps.index + 1}}</span>
                    </div>
                </div>
                <div class="flex-auto">
                    <h6 class="mb-2 font-bold">Semana {{slotProps.item.pbd_semana}} {{(slotProps.item.pbd_niveles ? "Nivel Dominio" : "")}}</h6>
                    <i class="pi pi-calendar mr-1 text-primary"></i>
                    <span class="mr-2">{{DateFormat(slotProps.item.pbd_fecha_desde)}}</span>
                    <i class="pi pi-calendar mx-1 text-primary"></i>
                    <span class="product-category">{{DateFormat(slotProps.item.pbd_fecha_hasta)}}</span>
                </div>
              
            </div>
        </template>
    </OrderList>

    <div class="flex justify-content-end" > 
        <Button label="Cancelar" icon="pi pi-times" class="p-button-danger p-button-text mx-2" @click="cancel_onClick()"/>
        <Button label="Aplicar Orden" class=" mt-2 p-button-info"  @click="reOrder_onClick()"/>
    </div>
     
</template>

<script>
import moment from 'moment'

export default {
    emits:['apply_onEmit',"cancel_onEmit"],
    props:{
        BloqueDetalleList:{
            type:Array,
            default(){
                return []
            }
        },
        service: null
    },
    data(){
        return{
           bloqueDetalleList : this.BloqueDetalleList 
        }
    },
    methods:{
        DateFormat(data) {
            return moment(data).format('DD/MM/YYYY')
        },

        async reOrder_onClick(){
            this.$confirm.require({
                message: '¿Está seguro de aplicar este orden?',
                header: 'Confirmación',
                icon: 'pi pi-exclamation-triangle text-yellow-600',
                acceptLabel: "Sí, aplicar",
                accept: async() => {
                    try {
                        var message = ""
                        var eElement =  this.bloqueDetalleList[0]; // First Element
                        var typeDetalle =  [...this.bloqueDetalleList]; //New Data for repository

                        typeDetalle.map((obj, index) =>{
                            obj.pbd_semana  = index + 1;
                        });
                        
                        await this.service.orderBloqueDetalle(eElement.pbd_codplb,typeDetalle).then(res  => message = res)
                        this.$toast.add({severity:'success', summary:'Reordenado', detail:message, life: 3000});
                        this.$emit("apply_onEmit");
                    }catch(ex)
                    {
                        var innerException =  "";
                        if(ex.response)
                            innerException = ex.response.data;  
                        else
                            innerException = ex;

                        this.$toast.add({severity:'error', summary:'Cancelado', detail:innerException, life: 3000});  
                        this.$catchError(ex);
                    }
                    
                }
            })
        },
        cancel_onClick(){
            this.$emit("cancel_onEmit");
        },
    }
}
</script>