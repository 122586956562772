<template>
    <!--Componente de Seleccion de Competenecias -->
    <div>
        <Dialog v-model:visible="comVisible"  header="Confirm"  @hide= "hide_event" :modal="true" :breakpoints="{'1360px': '95vw'}" :style="{width: '640px'}">
            <template #header>
                <div class="flex flex-column">
                    <h5 class="mb-3 font-bold mb-0">Competencias Generales</h5>
                    <span class=" text-sm font-bold text-muted"> Estas ya fueron definidas por por las autoridades académicas, por ser comunes a todas las carreras. 
                        Solamente seleccione las que se trabajarán en la asignatura.</span>
                </div>
            </template>
            <DataTable :value="competenciasList" selectionMode="multiple" :metaKeySelection="false" :rowHover="true" dataKey="cog_codigo" responsiveLayout="scroll" :loading="loading" v-model:selection="selectedCompetencias">
                 <template #header>
                    Header
                </template>
                <template #loading>
                    Cargando competencias. Espere por favor...
                </template>
                <template #empty>
                    Ninguna competencias encontrada.
                </template>
               
                <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                <Column field="cog_nombre" header="Competencia">
                    <template #body="slotProps">
                        <div class="flex flex-column">
                            <div class="flex align-items-center">
                                <div class="flex flex-column flex-grow-1">
                                    <a href="javascript:void(0)" class="text-gray-700 hover:text-primary mb-1 font-bold text-normal">{{slotProps.data.cog_nombre}}</a>
                                    <span class="text-muted font-bold text-xs">Competencia General</span>
                                </div>
                            </div>
                            <div class="pt-2">
                                <p class="text-gray-900 font-normal mb-2">{{slotProps.data.cog_competencia}}</p>
                            </div>
                        </div>
                    </template>
                </Column>
            </DataTable>
            <template #footer>
                <Button label="Cancelar" icon="pi pi-times"  class="p-button-danger p-button-text" @click="close_onClick"/>
                <Button label="Seleccionar" icon="pi pi-check" class="" @click="selected_onClick"   />
            </template>
        </Dialog>
    </div>
</template>
<script>


    import CompetenciaService from '@/service/competenciaService';

    export default {
        name:  "competencia",
        inheritAttrs: false,
        emits : ["update:isVisible", 'cancel_onEmit','selected'],
        props: {
            isVisible :{
                type : Boolean,
                default :  false
            }, // Visible
            
        },
        competenciaService: null,
        data(){
            
            return{
                comVisible :  this.isVisible,
                selectedCompetencias : null,
                competenciasList : [],
                loading : false,
                planeacionList : []
            }
        },

        created() {
            this.competenciaService = new CompetenciaService(this.axios);
        },

        async mounted() {
            
            this.loading = true;
            await this.competenciaService.getAllCompetencia().then(data => { this.competenciasList = data});

            this.loading = false;
        },
        methods:{
            close_onClick(){
                this.$emit('update:isVisible', !this.isVisible)
                this.$emit('cancel_onEmit')
                this.comVisible =  false;
            },
            hide_event(){
                this.$emit('cancel_onEmit')
            },
            selected_onClick(){
                this.$emit('selected', this.selectedCompetencias)
                this.comVisible =  false;
            }

        },

        updated() {
            if (this.isVisible) {
                this.comVisible = this.isVisible;
                this.selectedCompetencias = []
            }
        }


    }
</script>

