<template>
	<div class="grid">
		<div class="col-12 md:col-10 xl:col-10 xl:col-offset-1  md:col-offset-1">
			<!--Begin: Portada -->
            <div class="p-card">
                <div class="p-card-title">
                    <div class="card-header">
                        <div class="card-title">
                            <h5 class="ml-3 font-semibold text-gray-900 flex align-items-center"> <SelectedFileIco :size="'svg-icon-1'" class="mr-2" /> PLANEACIÓN DIDÁCTICA</h5>
                        </div>
                        <div class="card-toolbar">
                            <Button label="Imprimir"  icon="pi pi-print mr-2" class="p-button p-button-outlined ml-2" @click="printPlan_onClick()" :loading="loadingPrint" />
                            <Button label="Editar"  icon="pi pi-pencil" class="p-button-info ml-2" @click="editPlan_onClick()" />
                        </div>
                    </div>
                </div>
                <!--Begin: DATOS -->
                <div class="flex flex-wrap sm:flex-nowrap">
                    <div class="mr-6 mb-4">
                        
                    </div>
                    <div class="flex-grow-1"> 
                        <!-- Titulo -->
                        <div class="flex justify-content-between flex-wrap mt-1">
                            <div class="flex mr-1">
                                <a href="javascript:void(0)" class="font-bold mr-2 text-lg text-gray-800 hover:text-primary" >{{eGeneral.asignatura.plm_mat_nombre}}</a>
                                <div class="label label-lg label-inline font-semibold"
                                :class="'label-light-' + (selectedPlan.pld_estado === 'A' ? 'success' : (selectedPlan.pld_estado === 'R' ? 'warning' : 'primary') )">
                                    <i class="pi pi-star inline-flex md:hidden"></i>
                                    <span class="hidden md:inline-flex">{{selectedPlan.pld_estado === 'A' ? 'Aprobado' : (selectedPlan.pld_estado === 'R' ? 'En Revisión' : 'En Elaboración') }} </span>
                                </div>
                            </div>
                            <div class="lg:my-0 my-1">
                                <a href="javascript:void(0)" class="label label-lg  label-light-primary label-inline font-bold ml-2 py-3"> {{selectedPlan.pld_plan_acad}} </a>
                                <a href="javascript:void(0)" class="label label-lg label-light-danger label-inline font-semibold ml-1 py-3">{{selectedPlan.pld_nomcil}} </a>
                            </div>
                        </div>
                        <!-- Detalle -->
                        <div class="flex flex-wrap justify-content-between ">
                            <!-- Col 1 -->
                            <div class="flex flex-column flex-grow-1 ">
                                <!-- Bar Info -->
                                <div class="flex flex-wrap mb-2">
                                    <a href="javascript:void(0)" class=" flex align-items-center text-gray-600 font-semibold hover:text-primary sm:mr-5 mb-2"> 
                                        <i class="pi pi-th-large pi-fw  mr-1"></i> {{eGeneral.facultad.fac_nombre}}</a>
                                    <a href="javascript:void(0)" class="flex align-items-center text-gray-600 font-semibold sm:mr-5 mb-2"> 
                                        <i class="pi pi-shield pi-fw  mr-1"></i> {{eGeneral.carrera.car_nombre}}</a>
                                    <a href="javascript:void(0)" class="flex align-items-center text-gray-600 font-semibold sm:mr-5 mb-2"> 
                                        <i class="pi pi-tag pi-fw  mr-1"></i> {{selectedPlan.pld_version}}</a>  
                                    <a v-if="selectedPlan.pld_copy > 0" href="javascript:void(0)" class="flex align-items-center text-warning font-semibold sm:mr-5 mb-2"> 
                                        <i class="pi pi-copy pi-fw  mr-1"></i></a>  
                                </div>
                                
                                <!--Begin: Area Formacion -->
                                <div class="flex align-items-start flex-column py-1  ">
                                    <div class="font-bold text-gray-700 flex flex-column md:flex-row md:align-items-center">
                                        <span class="flex align-items-center"> Área de Formación </span>
                                        <span class="text-muted text-xs ml-2">Definida en el plan de estudios ↓ </span> 
                                    </div>
                                    <div v-if="areaFormacionList.length > 0"  class="flex flex-column pt-2" >
                                        <p class="text-gray-700 ml-3 flex align-items-center" v-for="area in areaFormacionList"  :key="area">
                                            <ComponentIco  class="mr-1"/>{{area.pdf_area}} 
                                            <a href="javascript:void(0)" class="ml-2 text-danger" @click="deleteArea_onClick(area)" v-if="allowEdit"> <i class="pi pi-times"/></a>
                                        </p>
                                        <a href="javascript:void(0)" class="ml-3 text-primary" @click="isDialogArea = true" v-if="allowEdit"> <i class="pi pi-plus-circle"/> Adicionar otra área de formación </a>
                                    </div>
                                    <div class="flex border-round bg-light-primary  py-2 px-4 mt-2 border-dashed-custom  border-primary " v-else> 
                                        <div class="block ">
                                            <a href="javascript:void(0)" class="font-bold text-primary">Área de Formación </a>  Área a la que pertenece la asignatura, en algunos descriptores podría aparecer como función clave.
                                            <a href="javascript:void(0)" class="font-bold ml-1 text-primary" @click="addAreaFormacion_onClick()">Agregar</a>
                                        </div> 
                                    </div>
                                </div>
                                <!--End: Area Formacion -->
                            </div>

                            <!-- Col 2 -->
                            <div class="flex align-items-center w-3 flex-auto  lg:mt-5 mt-3" style="float:right">
                                <span class="font-bold text-gray-600"> Revisión</span>
                                <ProgressBar :value="selectedPlan.pld_estado === 'A' ? 100 : 0" class="mx-3 flex-shrink-1"  :showValue="false" style="height:0.5rem;width: 75%;" />
                                <span class="font-bold w-3rem text-gray-600">{{selectedPlan.pld_estado === 'A' ? 100 : 0}} %</span>
                            </div>

                        </div>
                    </div>
                </div>
                <!--End: DATOS -->

                <!-- Begin : Cards Info -->
                <Divider />
                <div class="flex flex-wrap mx-6 ">
                    <div class="flex flex-column   flex-grow-1">
                        <div class="flex flex-wrap justify-content-between mb-2">
                            <!--Begin: Card Info--->
                            <div class="border-round border-dashed-custom  p-2 m-2 w-full md:w-9rem text-center hover:border-primary">
                                <div class="flex align-items-center justify-content-center">
                                    <PixelIco />
                                    <div class="text-lg font-semibold">{{eGeneral.asignatura.plm_uv}}</div>
                                </div>
                                <div class="font-semibold text-muted">U. V.</div>
                            </div>
                            <!--End: Card Info--->

                            <!--Begin: Card Info--->
                            <div class="border-round border-dashed-custom  w-full m-2  md:w-9rem p-2   text-center hover:border-primary">
                                <div class="flex align-items-center justify-content-center">
                                    <AlarmClockIco/>
                                    <div class="text-lg font-semibold">{{selectedPlan.pld_horas_apren + selectedPlan.pld_horas_ens}} Horas</div>
                                </div>
                                <div class="font-semibold text-muted">Total Horas</div>
                            </div>
                            <!--End: Card Info--->

                            <!--Begin: Card Info--->
                            <div class="border-round border-dashed-custom   w-full m-2  md:w-9rem p-2   text-center hover:border-primary">
                                <div class="flex align-items-center justify-content-center">
                                    <ClockIco /> <div class="text-lg font-semibold" >{{selectedPlan.pld_horas_ens}} Horas</div>
                                </div>
                                <div class="font-semibold text-muted">H. Enseñanza
                                    <b v-if="c_horasRecomendadas > 0" class="text-xs">{{c_horasRecomendadas.toFixed(2)}}% </b>
                                </div>
                            </div>
                            <!--End: Card Info--->

                            <!--Begin: Card -> Horas Aprendizaje -->
                                <div class="border-round border-dashed-custom  w-full m-2  md:w-9rem p-2  text-center hover:border-primary">
                                <div class="flex align-items-center justify-content-center">
                                    <TimerIco /> <div class="text-lg font-semibold">{{selectedPlan.pld_horas_apren}} Horas</div>
                                </div>
                                <div class="font-semibold text-muted ">H. Aprendizaje 
                                    <b v-if="c_horasRecomendadas > 0" class="text-xs">{{(100-c_horasRecomendadas).toFixed(2)}}%</b>
                                </div>
                            </div>
                            <!--End: Card -> Horas Aprendizaje--->
                        </div>
                    </div>
                </div>
                <div class="flex-grow-0">
                    <div class="flex border-round bg-light-warning p-2 my-2 border-dashed-custom  border-warning justify-content-center" v-if="c_horasRecomendadas != 40"> 
                        <div class="font-normal text-800  text-center">
                            La proporción recomendada es de <strong>40%</strong> horas de enseñanza y <strong>60%</strong> horas de aprendizaje
                        </div> 
                    </div>
                </div>
                <!--End: Cards Info -->
            </div>
            <!--End: Portada -->


            <div class="p-card mt-2 p-1">
				<TabView  lView ref="tabviewplan" @tab-change="tabView_onChange($event)">
                    <!--Begin: Panel Generalidades -->
                    <TabPanel>
                        <template #header>
                            <i class="pi pi-align-justify"></i>
                            <span class="hidden md:block pl-2">Generalidades</span>
                        </template>
                        <div class="mb-3">
                            <h5 class="mb-3  font-bold text-base sm:text-lg">PERFIL PROFESIONAL DE EGRESO</h5>
                            <div class="grid">
                                <!--Begin : Competencias Generales  -->
                                <div class="col-12 lg:col-5" :style="restringirClass">
                                    <h5 class="font-semibold text-lg flex align-items-center"><AngleDoubleIco/>Competencias generales</h5>
                                    <div class="flex border-round bg-light-primary py-2 px-4 mt-2 border-dashed-custom  border-primary mb-3"> 
                                        <div class="block ">
                                            <a href="javascript:void(0)" class="font-bold text-primary"> Competencias Generales</a>  Seleccione las que se trabajarán en esta asignatura.
                                            <a href="javascript:void(0)" class="font-bold ml-1 text-primary" @click="compentenciaSearch_onClick">Agregar</a>
                                        </div> 
                                    </div>
                                    <div v-for="(comp,index) in competenciasList" :key="index" class="">
                                      
                                        <div class="flex align-items-center">
                                            <div class="flex-grow-1 ">
                                                <p class="text-lg p-0 m-0 font-medium flex align-items-center"> <ComponentIco class="mr-2"/> {{comp.plc_competencia}}</p>
                                                <p class="font-italic mx-2">{{comp.cog_competencia}}</p>
                                            </div>
                                            <a href="javascript:void(0)" class="btn btn-bg-light-danger p-2 mr-2" v-tooltip.top="'Eliminar'" :key="index" @click="deleteComp_onClick(comp)" > 
                                                <i class="pi pi-trash text-danger"></i>
                                            </a>
                                          
                                        </div>
                                        <Divider type="dashed" class="p-divider-muted" />
                                    </div>
                                </div>
                                <!--End : Competencias Generales  -->
                                <div class="col-1">
                                    <Divider layout="vertical" class="hidden md:inline-flex"></Divider>
                                </div>
                                <!--Begin : Competencias Carrera/Asignatura  -->
                                <div class="col-12 lg:col-6" >
                                    <!--Begin : Competencias Carrera - Area Formacion  -->
                                    <h5 class="font-semibold sm:text-base text-lg">
                                        <span class="flex align-items-center"> <AngleDoubleIco/>Competencia específica de la carrera </span>
                                        <small class="text-muted ml-2"> Definida en plan de estudios ↓ </small>
                                    </h5>
                                    <div class="flex flex-column" v-if="areaFormacionList.length > 0">
                                        <p class="mt-1 mx-3" v-for="area in areaFormacionList"  :key="area"><ComponentIco/> {{area.pdf_competencia}} </p>
                                    </div>
                                    <div class="flex border-round bg-light-primary py-2 px-4 border-dashed-custom  border-primary" v-else> 
                                        <div class="block ">
                                            <a href="javascript:void(0)" class="font-bold text-primary"> No definida</a> La competencia específica de la carrera obedece al área de formación (por tanto, coincide para todas las asignaturas de esa misma área de formación).
                                            <a href="javascript:void(0)" class="font-bold ml-1 text-primary" @click="addAreaFormacion_onClick()">Agregar</a>
                                        </div> 
                                    </div>
                                    <!--End : Competencias Carrera - Area Formacion  -->


                                    <h5 class="font-semibold sm:text-base text-lg help">
                                        <span class="flex align-items-center"> <AngleDoubleIco/>Competencia específica de la asignatura</span>
                                    </h5>
                                    <p class="font-italic text-danger" :class="['help-child' , selectedPlan.pld_com_asignatura ]">Asegúrese que su estructura contenga: Verbo en infinitivo + objeto conceptual + finalidad + condición de referencia y forma de apropiación.</p>
                                    <div class="flex flex-column">
                                        <p class=" mt-1 mx-3" ><ComponentIco/> {{selectedPlan.pld_com_asignatura}} </p>
                                    </div>
                                </div>
                                <!--End : Competencias Carrera/Asignatura  -->
                            </div>
                        </div>
                        <!--End: PERFIL -->
                        <Divider />
                        <div class="mb-6">
                            <h2 class="mb-3 text-xl font-bold help">SITUACIÓN PROBLEMÁTICA</h2>
                            <p class="font-italic help-child text-danger">Procure no enfocarse en temas o contenidos; sino, relaciónelo con la realidad, con uno o más problemas del contexto a los que se puede contribuir a resolver desde esta asignatura.</p>
                            <p>{{selectedPlan.pld_problematica}}</p>
                            
                        </div>
                    </TabPanel>
                    <!--End: Panel Generalidades -->

                    <!--Begin: Planeacion Didactica Bloques  (RefBK) Componente al final-->
                    <TabPanel>
                        <template #header>
                            <i class="pi pi-table"></i>
                            <span class="hidden md:block pl-2">Planeación Competencia</span>
                        </template>
                        <div class="m-0">
                            <div class="flex justify-content-between align-items-center flex-wrap flex-column md:flex-row" >
                                <div class="flex flex-column mb-3" >
                                    <h5 class="mb-0 font-bold text-base sm:text-lg">PLANEACIÓN DIDÁCTICA DE COMPETENCIA</h5>
                                    <small  class="text-muted font-semibold text-sm">Bloques de aprendizaje</small>
                                </div>
                                <div class="flex align-items-end" >
                                    <Button label="Nuevo" icon="pi pi-plus-circle"  v-tooltip.top="'Nuevo Bloque'" @click="$refs.cPlanBloque.createNewBloque_onClick()"  />
                                </div>
                            </div>
                            <!--Begin: Bloque Card-->
                                <div class="flex flex-column md:flex-row ">
                                    <div class="flex m-2 " v-for="bloque, index in bloqueList" :key="bloque">
                                        <a href="javascript:void(0)" class="flex flex-row justify-content-center align-items-start  p-3  border-round border-dashed-custom border-gray-400 p-2  hover:bg-blue-50   w-full md:w-9rem"
                                        :class="{ 'bg-blue-50': bloqueSelectedIndex == index }" @click="$refs.cPlanBloque.viewBloque_onClick(bloque,index)">
                                            <span class="mr-2">
                                                <i class="pi pi-book text-4xl  text-gray-400"></i>
                                            </span>
                                            <div class="flex flex-column ">
                                                <span class="text-gray-700 font-bold text-base">Bloque {{index+1}}</span>
                                                <a href="javascript:void(0)" class="text-primary font-semibold" >Ver</a>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            <!--End: Bloque Card-->

                            <!--Begin : Message -->
                            <div class="flex justify-content-center border-round bg-light-primary p-2 mt-2 border-dashed-custom  border-primary p-component" v-if="bloqueSelectedIndex <= -1"> 
                                <div class="block">
                                    <a href="javascript:void(0)" class="font-bold text-primary">Bloques de aprendizaje.</a> Se diseña según cada cómputo de la asignatura.
                                    <a href="javascript:void(0)" class="font-bold ml-1 text-primary " @click="$refs.cPlanBloque.createNewBloque_onClick()">Agregar Bloque</a>
                                </div> 
                            </div>
                            <!--End : Message -->
                        </div>
                    </TabPanel>
                    <!--End: Planeacion Didactica Bloques -->

                    <!--Begin: Fuentes y Recursos -->
                    <TabPanel>
                        <template #header>
                            <i class="pi pi-book"></i>
                            <span class="hidden md:block pl-2">Recursos</span>
                        </template>
                         <div class="mb-6">
                            <h5 class="mb-1 text-base sm:text-lg font-bold">FUENTES DE INFORMACIÓN</h5>
                            <small  class="text-muted font-semibold text-base"></small>
                            <div class=" border-round bg-light-primary py-2 px-4  border-dashed-custom  border-primary"> 
                                <div class="sm:block md:flex">
                                    Bibliografía, revistas y los enlaces virtuales (URL, Vídeos, Tutoriales) a libros que estén en las bases de datos o bibliotecas digitales. 
                                    <a href="javascript:void(0)" class="font-bold ml-1 text-primary" @click="fuenteAdd_onClick">Agregar</a>
                                </div> 
                            </div>
                            <div class="grid mt-3">
                                <!--Begin : Recurso Tipo de fuente  -->
                                <SectionCard v-for="fuente, index in fuenteGroups" :key="index" :itemGroup="fuente" @delete_onClick="fuenteDelete_onClick" />
                                <!--Begin : Recurso Tipo de fuente  -->
                            </div>
                        </div>
                    </TabPanel>
                    <!--Begin: Fuentes y Recursos -->

                    <!--Begin: Comision -->
                    <TabPanel>
                        <template #header>
                            <i class="pi pi-id-card"></i>
                            <span class="hidden md:block pl-2">Comisión</span>
                        </template>
                        <div class="flex flex-column" :style="restringirClass">
                            <h2 class="mb-1 text-base sm:text-lg font-bold">APROBACIÓN</h2>
                            <small  class="text-muted font-semibold text-base">Comisión que elaboró la planificación didáctica</small>
                            <div class="flex border-round bg-light-primary py-2 px-4  border-dashed-custom  border-primary my-2 "> 
                                <div class="block">
                                    <a href="javascript:void(0)" class="font-bold mx-1 text-primary">Comisión </a> 
                                    Docentes que crearán colaborativamente, validarán y aplicarán esta planificación.
                                    <a href="javascript:void(0)" class="font-bold ml-1 text-primary" @click="openDialogEmpleado_onClick()">Agregar</a>
                                </div> 
                            </div>
                            <Divider align="right" class="m-3"/>
                            <!--Begin: Comision List-->
                            <div class="grid">
                                <div class="col-12  md:col-6 lg:col-4" v-for="com,index in comisionList" :key="index">
                                    <div class="mb-1 flex align-items-start">
                                        <div class="mr-2" >
                                            <div class="symbol symbol-40 symbol-circle shadow-4" :class="'symbol-light-'+ (com.coe_estado === 'E' ? 'danger' : 'primary')"
                                                >
                                                <span class="symbol-label font-bold ">{{com.emp_nombres_apellidos[0].toUpperCase()}}</span>
                                            </div>
                                        </div>
                                        <div class="flex-grow-1">
                                            <a href="javascript:void(0)" class="text-gray-900 font-medium  hover:text-primary text-lg">{{com.emp_nombres_apellidos}}</a>
                                            <span class="text-gray-500 font-medium block"> {{com.coe_puesto}}</span>
                                            <span class="text-gray-500 block uppercase text-sm"> {{com.emp_sede}}</span>
                                        </div>
                                    </div>
                                    <Divider class="m-1 p-0" />
                                    <div class="flex justify-content-end">
                                        <div class="flex align-items-center mr-3">
                                            <a href="javascript:void(0)" class="p-2 mr-2 align-self-center text-danger" v-tooltip.top="'Eliminar'" :key="index"  @click="deleteEmp_onClick(com)" > 
                                                <i class="pi pi-trash  mr-1 text-gray-600"></i> Eliminar
                                            </a>
                                        </div>
                                        <div class="flex align-items-center mr-3" v-if="com.coe_estado == 'A'">
                                            <a href="javascript:void(0)" class="p-2 mr-2 align-self-center text-primary "  v-tooltip.top="'Edición'" :key="index"  @click="enableEmp_onClick(com)" > 
                                                <i class="pi pi-pencil mr-1 text-gray-600"></i> Habilitar Edición
                                            </a>
                                        </div>
                                    </div>
                                    <Divider type="dashed"/>
                                </div>
                            </div> 
                             <!--End: Comision List-->
                        </div>
                        <!--Begin: Indicator => approve -->
                        <div class="h-3px w-100 bg-light-danger p-0 m-0" ></div>
                        <div class="flex  flex-column border-round bg-light-primary p-4  border-dashed-custom  border-primary my-1 " v-if="c_isComision">  
                            <div class="flex flex-shrink-0">
                                <DoubleCheckIco :size="'svg-icon-2tx'" class="mr-3"/>
                                <div class="flex justify-content-between align-items-center flex-grow-1 flex-wrap md:flex-nowrap" v-if="c_isApprove">
                                    <div class="mb-3 mb:mb-0 font-semibold">
                                        <h5 class="text-gray-800 font-bold py-0 my-0" >¡Planificación ha sido validada anteriormente! </h5>
                                        <div class="text-base text-gray-600 pr-2 "> La planificación será enviada al vicedecano quien la revisará hasta que toda la comisión la haya validado. </div>
                                    </div>
                                </div>
                                <div class="flex justify-content-between align-items-center flex-grow-1 flex-wrap md:flex-nowrap" v-else>
                                    <div class="mb-3 mb:mb-0 font-semibold">
                                        <h5 class="text-gray-800 font-bold py-0 my-0" >¡Planificación no ha sido validada por este usuario! </h5>
                                        <div class="text-base text-gray-600 pr-2 "> La planificación deberá enviarse al vicedecano, quien la revisará utilizando el instrumento posterior. </div>
                                    </div>
                                    <a href="javascript:void(0)" class="p-button font-bold ml-1  align-self-center" @click="approveComision_onClick()"> 
                                        <i class="pi pi-checkb pi-pw mr-1" /> Validar</a>
                                </div>
                            </div> 
                        </div>
                        <!--End: Indicator => approve -->
                    </TabPanel>
                    <!--End: Comision -->
                </TabView >
			</div>
            
            

            <!--Begin: Ref Bloque  (RefBK)-->
            <!--allowEdit: Permisos de edicion -->
            <PlanBloque  ref="cPlanBloque" v-model:Index="bloqueSelectedIndex"  v-model:BloqueList="bloqueList"  :AllowEdit="allowEdit" :Codigo="codigoPlan" :Competencia="selectedPlan.pld_com_asignatura" :Comision="comisionList" v-if="isBloquePanel" :Asignatura="eGeneral.asignatura"/>
            <!--End: Ref Bloque -->
		</div>
	</div>

    <RevisionAside v-model:PlanDidactico="selectedPlan" />
<!--Begin: Dialog Components -->
    <!--*** Components : Search Competencias -->
    <DialogComp :isVisible="isCompDialog"   @cancel_onEmit="isCompDialog = false;"  @selected="competenciaSelected_onClick"/>
    <!--*** Components : Add Fuentes -->
    <DialogFuent :isVisible="isFuentDialog"   @cancel_onClick="isFuentDialog = false;"  :tipoFuenteList="tipoFuenteList" :itemSelected="selectedFuente" @save_onClick="fuenteSave_onClick"/>

    <!--*** Components : Search Empleado For Comision --->
    <Dialog v-model:visible="isDialogEmpleado" :breakpoints="{'1360px': '95vw'}" :style="{width: '50vw'}" :modal="true">
        <template #header>
             <h5 class=" font-semibold text-gray-900 flex align-items-center" ><GroupIco size="svg-icon-1"  class="mr-2"/> BUSCAR EMPLEADO</h5>
        </template>
        
        <EmpleadoDataTable  v-model:selectedItems="resultSelectedEmpleado"> </EmpleadoDataTable>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times"  class="p-button-danger p-button-text" @click="cancelSearchEmpleado_onClick()"/>
            <Button label="Aceptar" icon="pi pi-check" class=""  @click="selectSearchEmpleado_onClick()"  />
        </template>
    </Dialog>
    <!--*** Components : Search Empleado For Comision --->

    <!--*** Components : Edit Plan -->
    <Dialog v-model:visible="isEditPlanDialog" :modal="true"  :draggable="false">
        <template #header>
            <div class="flex align-items-center">
             <SelectedFileIco/> <span class="font-semibold text-gray-900 sm:text-lg text-xxl">EDITAR PLANIFICACIÓN DIDÁCTICA</span>
            </div>
        </template>
        <EditPlan :PlanDidactico="selectedPlan" :service="planDidacticoService" @save_onEmit="savePlan_onClick" @cancel_onEmit="isEditPlanDialog = false"/>
    </Dialog>
    <!--*** Components : Edit Plan -->

    <Dialog v-model:visible="isStartDialog" :modal="true" :style="{width: '460px'}" :closable="false">
        <template #header>
                <h4 class="font-semibold text-gray-900"></h4>
        </template>
        <form @submit.prevent="handleSearch_onSubmit($event)" >
            <div class="grid">
                <div class="col-12 py-2">
                    <div class="m-0 text-center" >
                        <h2 class="mb-1 text-xl font-bold">BUSCAR PLAN</h2>
                        <small class="text-muted font-semibold text-base" >Ingrese código de plan</small>
                    </div>
                </div>
                <div class="col-12" >
                    <InputText  id="searchInput" class="w-full text-center text-4xl text-muted" v-model="codigoPlan"/>
                </div>
                <div class="col-12">
                    <Button type="submit" label="Buscar" class="w-full text-center" :loading="searching"/>
                </div>
            </div>
        </form>
        <div class="flex justify-content-end p-2" >
            <router-link  class="text-primary"  :to="{ name: 'Home'}">
                <span class="pi pi-home"> Inicio</span>
            </router-link>
        </div>
    </Dialog>

    <!--Begin: Dialogo Area -->
    <Dialog v-model:visible="isDialogArea" :style="{width: '640px'}"  :modal="true" @hide="cancelArea_onClick()">
        <template #header>
            <h5 class="font-bold">Áreas de Formación</h5>
        </template>
        <DataAreaFormacion  v-model:SelectedItems="selectedArea" :Descriptor="eGeneral.asignatura.plm_mat_nombre"   :PlanAcademico="eGeneral.planAcademico"/>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times"  class="p-button-danger p-button-text" @click="cancelArea_onClick()"/>
            <Button label="Seleccionar" icon="pi pi-check" class=""  @click="selectedArea_onClick()"  />
        </template>
    </Dialog>
    <!--End: Dialogo Area -->

    <!--Begin: Dialogo Impresión -->
    <Dialog v-model:visible="isPrintDialog" :modal="true" class="p-dialog-maximized pb-2" :closeOnEscape="false" >
        <template #header >
            <div class="h-100 bg-primary p-0 mr-1" style="width:2px"></div>
            <div class="flex w-full justify-content-between md:flex-row flex-column" >
                <div class="flex flex-column">
                    <h5 class="m-0 font-bold text-primary">{{eGeneral.asignatura.plm_mat_nombre}} <span class="lowercase text-xs text-danger">{{selectedPlan.pld_version}}</span></h5>
                    <div class="text-muted text-lg sm:text-xs">{{eGeneral.facultad.fac_nombre}} > {{eGeneral.carrera.car_nombre}}</div>
                </div>
               
            </div>
           
        </template>
        <RevisionData  ref="reviewPlan" :PlanDidactico="selectedPlan" :Comision="comisionList" v-if="isPrintDialog" />
        <div class="flex justify-content-end my-2" >
             <div class="flex align-items-end mr-3 justify-content-end">
                <a href="javascript:void(0)" class="text-danger font-bold" @click="$refs.reviewPlan.openReview_onclick(eGeneral.carrera.car_nombre,eGeneral.facultad.fac_nombre )">
                    <i class="pi pi-check-square text-primary" />    Historial Revisión
                </a>
            </div>
        </div>
        <!-- <iframe :src="printElement" type="application/pdf"  class="w-full h-full" /> -->
        <object :data="printElement" type="application/pdf"   scrolling="auto" class="w-full h-full">
            <embed :src="printElement" type="application/pdf" />
        </object>
    </Dialog>
    <!--End: Dialogo Impresión -->

    <!--Begin: Habilitar -->
    <Dialog header="Habilitar Empleado" v-model:visible="displayConfirmation" :style="{width: '350px'}" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-check text-success mr-1" style="font-size: 2rem" />
            <span>¿Está seguro de  habilitar?</span>
            <div class="p-fluid mt-4 ">
                <div class="field">
                    <label for="rev_comentario" class="text-sm"> Comentario/Observación<small id="plm_area_form" class="p-error">*</small></label>
                    <Textarea  ref="rev_comentario" id="rev_comentario" v-model.trim="commentNote" rows="5"  cols="20"
                    :autoResize="true"  placeholder="" />
                    <div class="flex justify-content-end" :class="{'text-danger': (commentNote.length > 2500)}">
                        <small>{{commentNote.length}}/2500</small>
                    </div>
                    <small v-if="submittedEnable" class="p-error">* Ingrese comentario válido</small>
                </div>

                <div class="field-checkbox">
                    <Checkbox  name="chk_todo" :binary="true" v-model="enableAll"/>
                    <label for="chk_todo">Habilitar toda la comisión</label>
                </div>
            </div>

            
            <p class="text-sm">La planificación cambiará a estado <strong>"En Elaboración"</strong></p>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" @click="displayConfirmation = false" class="p-button-text p-button-danger"/>
            <Button label="Sí, Habilitar" icon="pi pi-check" @click="applyEnableEmp_onClick()" class="" autofocus />
        </template>
    </Dialog>
    <!--Begin: Habilitar -->

    
<!--End: Dialogo Components -->
</template>

<script>
//SVG
import {Clock, Pixel, AlarmClock, Timer, DoubleCheck, AngleDouble, SelectedFile,Component,Group } from '@/components/svg/_svgHelper.js'

//SERVICES
import PlanDidacticoService from '@/service/planDidacticoService'
import FacultadService from '@/service/facultadService'

//Components
import PlanBloque from "@/components/LaborDocente/Planificacion/PlanBloque"
import DialogCompetencia from  '@/components/Generales/DialogCompetencias.vue'
import DialogFuente from  '@/components/LaborDocente/Planificacion/CreateFuente'
import EmpleadoDataTable from "@/components/Generales/EmpleadoDt"
import EditPlan from '@/components/LaborDocente/Planificacion/EditPlan.vue'
import RevisionData from '@/components/LaborDocente/Revision/RevisionData'
import DataAreaFormacion from "@/components/Generales/DataAreaFormacion"
import SectionCardGroup from  '@/components/cards/SectionCardGroup'
import RevisionAside from '@/components/LaborDocente/Revision/RevisionAside'


const ENABLEELEMENT = "pointer-events: none;"
export default {
    planDidacticoService : null,
    facultadService : null,
    data(){
        return{
            codigoPlan : 0,
          
            selectedPlan : {}, // Plan Detalle
            eGeneral: { //Generalidades del Plan
               asignatura : {},
               planAcademico : {},
               carrera : {},
               facultad: {}
            },

            competenciasList :  [],
            comisionList : [],
            selectedComision:{},
            tipoFuenteList : [],
            fuenteList : [],
            areaFormacionList : [],
            bloqueList : [],
            bloqueSelectedIndex : -1,
            resultSelectedEmpleado : [],
            selectedArea : [],
            selectedFuente: {},

            //Enviar observacion

            submittedEnable : false,
            commentNote: "",
            displayConfirmation : false,
            enableAll : false,

           /*Dialogs and Logic Control*/
            isCompDialog : false,
            isDialogEmpleado : false,
            isFuentDialog :  false,
            isBloquePanel :  false,
            isEditPlanDialog :  false,
            isStartDialog : false,
            isPrintDialog  : false,
            isDialogArea :  false,
           

            //indicators 
            searching : false,
            loadingPrint : false,
            //style
            restringirClass : "",
            printElement : undefined,
            allowEdit : false,
            
        }
    },
    created(){
        this.planDidacticoService  = new PlanDidacticoService(this.axios);
        this.facultadService  = new FacultadService(this.axios);
    },
    async mounted(){
        /*Verificamos si no hay parametros para levantar la busqueda de un plan */
        var rParams = this.$route.params.id
        if(rParams == "" || rParams === undefined){
            this.isStartDialog = true;
            return;
        }

        this.codigoPlan =  parseInt(rParams)
        await this.init();
    },
    methods:{
        async init(){
            try{
                this.searching = true;
                if(this.codigoPlan == 0){
                    return;
                }
                await this.fx_LoadPlan(this.codigoPlan);
                await this.fx_getComision();
                this.isStartDialog = false;
                this.codigoPlan =  parseInt(this.codigoPlan)

                /*Get Generalidades */
                await this.facultadService.getAsignatura(this.selectedPlan.pld_codplm).then(res => this.eGeneral.asignatura = res) //Asigntura

                await this.facultadService.getPlan(this.eGeneral.asignatura.plm_codpla).then(res => this.eGeneral.planAcademico = res)//Plan Academico by Asigntura Code
                await this.facultadService.getCarrerra(this.eGeneral.planAcademico.pla_codcar).then(res => this.eGeneral.carrera = res)//Carrera by Plan Cod Carrera
                await this.facultadService.getFacultad(this.eGeneral.carrera.car_codfac).then(res => this.eGeneral.facultad = res)

                
                await this.fx_getCompetencia();
                await this.fx_getAreaFormacion();
                await this.planDidacticoService.getTiposFuente().then(res=> this.tipoFuenteList = res)
                await this.fx_getFuentes();
              
            }catch(ex){
                this.$catchError(ex)
                if(ex.response)
                    if(ex.response.status == 400)
                        this.$router.push({name: "error"})

                    else if(ex.response.status == 404)
                        this.$router.push({name: "notfound"})
            }finally{
                this.searching = false;
            }
        },
        //Cargar el plan didáctico de la busqueda
        async fx_LoadPlan(codPlan){
            //Asignando restriccion de visualizacion de Todos los datos o solo asignados
            var empComision = !this.$store.getters.evalAuth("VerTodos") ? this.$store.getters.getEmpId : 0;
            await this.planDidacticoService.getPlan(codPlan,empComision).then(res => {
                if(res == ""){
                    throw "No se encontrarón resultados o no está autorizado"
                }
                this.selectedPlan = res
            })

            //Firts Tab
            if(!this.$store.getters.evalAuth("TabGeneral")){
                this.restringirClass =  ENABLEELEMENT   
                return;
            }
        },

        async fx_getComision(){
            await this.planDidacticoService.getComision(this.selectedPlan.pld_codigo).then(res=> {
                this.comisionList = res
                this.allowEdit =  this.setAllowEdit();
            })
        },
        handleSearch_onSubmit(event){
            event.preventDefault();
            this.init();
        },
        async printPlan_onClick(){
            try {
                this.loadingPrint =  true
                await this.planDidacticoService.printPlan(this.selectedPlan.pld_codigo).then(res  =>{
                    const url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/pdf'}));
                    this.printElement  = url + `#filename=${this.eGeneral.asignatura.plm_mat_nombre}.pdf`  
                })

                this.isPrintDialog  = true;
            }catch(ex){
                this.$catchError(ex)
            }finally{
                this.loadingPrint =  false
            }
           
            //this.printElement = pdf.createLoadingTask(el);
              
        },

        tabView_onChange(evt){
            this.restringirClass =  "";
            if(evt.index == 0){
                if(!this.$store.getters.evalAuth("TabGeneral")){
                    this.restringirClass =  ENABLEELEMENT   
                }
            }else if(evt.index == 3){
                if(!this.$store.getters.evalAuth("TabComision")){
                    this.restringirClass = ENABLEELEMENT 
                }
            }

            this.isBloquePanel = evt.index == 1;
        },

        async fx_getFuentes(){
            await this.planDidacticoService.getFuentes(this.selectedPlan.pld_codigo).then(res=> this.fuenteList = res)
        },

        async fx_getCompetencia(){
            await this.planDidacticoService.getCompetencias(this.selectedPlan.pld_codigo).then(res=> this.competenciasList = res) 
        },

        async fx_getAreaFormacion(){
            await this.planDidacticoService.getAreaFormacion(this.selectedPlan.pld_codigo).then(res=> this.areaFormacionList = res) 
        },


       
        //#region Plan Academico 
        
        editPlan_onClick(){
            if(!this.$store.getters.evalAuth("btnEdit", this.$toast)){
                return;
            }
            if(!this.canEdit()) return;

            this.isEditPlanDialog =  true;
        },

        savePlan_onClick(){
            this.fx_LoadPlan(this.selectedPlan.pld_codigo);
            this.isEditPlanDialog =  false;

        },

        //#endregion Plan Didactico

        //#region Competencias
        compentenciaSearch_onClick(){
            if(!this.canEdit()) return;
            this.isCompDialog  = true;
        },
      
        async competenciaSelected_onClick(value){
                await Promise.all(value.map(async (object)=>{
                    //add Upsert DataBase List
                        return await this.planDidacticoService.createCompetencia({
                            codigo : this.selectedPlan.pld_codigo,
                            cod_competencia : object.cog_codigo
                        }).then(res => res );
                    })
                );
                await this.fx_getCompetencia();
        },

        deleteComp_onClick(data){ // Eliminar competencia
            if(!this.canEdit()) return;

            var message ="";
            this.$confirm.require({
                message: `¿Está seguro de eliminar este competencia: ${data.plc_competencia}?`,
                header: 'Eliminar competencia',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: async () => {
                    await this.planDidacticoService.deleteCompetencia(data.plc_codigo).then(res  => message = res)
                    this.$toast.add({severity:'info', summary:'¡Eliminado!', detail:message, life: 3000});
                    await this.fx_getCompetencia();
                },
            });
        },

        //#endregion Competencias

       //#region Comision
        async openDialogEmpleado_onClick(){
            if(!this.canEdit()) return;
            this.resultSelectedEmpleado =  this.comisionList.map(function(obj){
                var rObj = {
                    emp_codigo: obj.emp_codigo,
                    emp_puesto: obj.coe_puesto,
                    emp_sede: obj.emp_sede,
                    emp_nombres_apellidos: obj.emp_nombres_apellidos,
                    asignado :  true, // local properties
                }
                return rObj;
            });
            this.isDialogEmpleado = true
        },

        cancelSearchEmpleado_onClick(){
           this.isDialogEmpleado = false;
           this.resultSelectedEmpleado = [];
        },

        async selectSearchEmpleado_onClick(){
            try{
                var newComisionEmpleado = this.resultSelectedEmpleado.filter(x=> !x.asignado);
                if(newComisionEmpleado.length > 0){
                    await this.planDidacticoService.createComision(this.selectedPlan.pld_codigo,newComisionEmpleado).then(res=> res);
                    await this.planDidacticoService.getComision(this.selectedPlan.pld_codigo).then(res=> this.comisionList = res)
                }
               
                this.isDialogEmpleado =  false;
            }catch(ex){
               this.$catchError(ex);
           }
        },

        deleteEmp_onClick(comision){ // Eliminar Empleado de la comision
            if(!this.canEdit()) return;
            this.$confirm.require({
                message: `¿Está seguro que desea eliminar este empleado:  ${comision.emp_nombres_apellidos}?`,
                header: 'Eliminar Empleado',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: async () => {
                    try{
                        var message = "";
                        await this.planDidacticoService.deleteComision(comision.coe_codigo).then(res=> message =  res)
                        var idx =  this.comisionList.findIndex(x=> x.coe_codigo ==  comision.coe_codigo);
                        this.comisionList.splice(idx, 1)
                        this.$toast.add({severity:'info', summary:'Eliminado', detail:message, life: 3000});
                    }catch(ex){
                        this.$catchError(ex);
                    }
                    
                    
                },
                reject: () => {
                    this.$toast.add({severity:'error', summary:'Cancelado', detail:'La solicitud fue cancelada', life: 3000});
                }
            });
        },


        enableEmp_onClick(comision){ // Eliminar Empleado de la comision
            if(!this.canEdit()) return;
            this.selectedComision =  comision;
            this.displayConfirmation  = true;
            this.commentNote =  "";
            this.enableAll =  false
            this.submittedEnable =  false;
        },

        async applyEnableEmp_onClick(){
            try{
                this.submittedEnable=  true;
                if(!this.commentNote.trim()) return;

                var message = "";
                //Selected comision for notification
                var comisionNotiList = [ this.selectedComision];
                if(this.enableAll)
                    comisionNotiList = this.comisionList
                
                await this.planDidacticoService.enableComision(this.codigoPlan, this.commentNote, comisionNotiList).then(res=> message =  res)
                await this.fx_getComision();
                this.$toast.add({severity:'info', summary:'Habilitado', detail:message, life: 3000});

                this.displayConfirmation =  false;
            }catch(ex){
                this.$catchError(ex);
            }
        },

        async approveComision_onClick(){
            //Begin: Comision 
            //** Pertenece a la comision buscar referencia de empleado y comision ID**/
            if(!this.canEdit()) return;
            var emp = this.comisionList.filter(x=> x.emp_codigo == this.$store.getters.getEmpId);  
            if(emp.length <=  0){
                this.$toast.add({severity:'warn', summary:'¡Error!', detail: "Usted no esta en la lista de comisión para validar este registro", life: 3000});
                return;
            }
            //End: Comision

            var message =  "";
            this.$confirm.require({
                message: `¿Seguro que quieres marcar como validada esta planificación?`,
                header: 'Validar Planificación',
                icon: 'pi pi-question-circle',
                acceptClass: 'p-button-danger',
                accept: async() => {
                    await this.planDidacticoService.approveComision(this.codigoPlan, this.$store.getters.getEmpId).then(res  => message = res)
                    this.$toast.add({severity:'info', summary:'¡Eliminado!', detail:message, life: 3000});
                    // var idx =  this.comisionList.findIndex(x=> x.emp_codigo ==  this.$store.getters.getEmpId);
                    // this.comisionList[idx].coe_estado = "A";
                    await this.fx_getComision();
                }
            });
        },
       //#endregion Comision


       //#region  Fuentes
        fuenteAdd_onClick(){
            if(!this.canEdit()) return;
            this.selectedFuente = {},
            this.isFuentDialog =  true;
        },

        async fuenteSave_onClick(value)
        {
            try{
                await this.planDidacticoService.createFuente(this.selectedPlan.pld_codigo, value).then(res => res);
                await this.fx_getFuentes();
                 this.$toast.add({severity:'success', summary:'¡Exito!', detail: "Fuente agregada con éxito", life: 3000});
            }catch(ex){
                this.$catchError(ex);
            }
        },

        async fuenteDelete_onClick(data){
            if(!this.canEdit()) return;
            var message = "";
            this.$confirm.require({
                message: `¿Seguro que quieres eliminar este recurso: ${data.fin_nombre} ?`,
                header: 'Eliminar Fuente',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: async() => {
                    await this.planDidacticoService.deleteFuente(data.fin_codigo).then(res  => message = res)
                    this.$toast.add({severity:'info', summary:'¡Eliminado!', detail:message, life: 3000});
                    var idx =  this.fuenteList.findIndex(x=> x.fin_codigo ==  data.fin_codigo);
                    this.fuenteList.splice(idx, 1)
                },
            });
        },
       //#endregion End: Fuente


        //#region Begin: Area Formacion
        addAreaFormacion_onClick(){
            if(!this.canEdit()) return;
            this.cancelArea_onClick()
            this.isDialogArea = true
        },
        cancelArea_onClick(){
            this.isDialogArea =  false
            this.selectedArea = this.areaFormacionList.map(object => ({ paf_codigo : object.pdf_codpaf}))
        },

        async selectedArea_onClick(){
            try{
                await Promise.all(this.selectedArea.map(async (object) => {
                        var idx =  this.areaFormacionList.findIndex(x=> x.pdf_codpaf ==  object.paf_codigo); 
                        if(idx <= -1){
                            // Verifico si existe
                            await this.planDidacticoService.createAreaFormacion({
                                pdf_codpla : this.selectedPlan.pld_codigo,
                                pdf_codpaf : object.paf_codigo
                            }).then(res  => 
                            {
                                this.areaFormacionList.push(res)
                            })
                        }
                    })
                )
                this.cancelArea_onClick()
            }catch(ex){
                this.$catchError(ex)
            }
        },

        deleteArea_onClick(area){
            if(!this.canEdit()) return;
            var message = "";
            this.$confirm.require({
                message: `¿Seguro que quieres eliminar este área: ${area.pdf_area} ?`,
                header: 'Eliminar Área',
                icon: 'pi pi-info-circle',
                acceptLabel : "Sí, eliminar.",
                acceptClass: 'p-button-danger',
                accept: async() => {
                    await this.planDidacticoService.deleteAreaFormacion(area.pdf_codigo).then(res  => message = res)
                    this.$toast.add({severity:'info', summary:'¡Eliminado!', detail:message, life: 3000});
                    var idx =  this.areaFormacionList.findIndex(x=> x.pdf_codigo ==  area.pdf_codigo);
                    this.areaFormacionList.splice(idx, 1);
                },
            });
        },
        //#endregion End: AWrea Formacion

        //#region Permisos
        setAllowEdit(){

            if(this.selectedPlan.pld_estado === 'A' ){
                return false;
            }
            else{
                //Permitir edición para comisión si no ha validado.
                var emp = this.comisionList.filter(x=> x.emp_codigo == this.$store.getters.getEmpId); 
                if(emp.length >  0){
                    var comisionado = emp[0];
                    return comisionado.coe_estado != "A" // aprobado
                }else{
                    return true;
                }
            }
        },

        canEdit(){
            if(!this.allowEdit){
                this.$toast.add({severity:'warn', summary:'¡Autorización!', detail:"El documento no puede ser modificado. Verifique que no está marcado como 'validar' o 'aprobado'", life: 3000});
                return false;
            }
            return true;
        },
        //#endregion Permisos

        

    },
    computed:{
        fuenteGroups(){
            return this.$methods.groupBy(this.fuenteList, "fin_codtif")
        },
        c_horasRecomendadas(){  //Para enseñanza
            var totalHoras =  this.selectedPlan.pld_horas_apren + this.selectedPlan.pld_horas_ens
            //enseñanza es igual a 40%
            if(totalHoras > 0){
                var porcentaje =  (this.selectedPlan.pld_horas_ens / totalHoras).toFixed(2) * 100
                return porcentaje 
            }else{
                return 0
            }
        },

        c_isApprove(){ // si esta aprobada por la comision
            var emp = this.comisionList.filter(x=> x.emp_codigo == this.$store.getters.getEmpId);  
            
            if(emp.length >  0){
               return emp[0].coe_estado == "A"
            }
            return false;
        },

        c_isComision(){ // si esta aprobada por la comision
            var emp = this.comisionList.filter(x=> x.emp_codigo == this.$store.getters.getEmpId);  
            
            if(emp.length >  0){
               return true;
            }
            return false;
        },
    },
    components:{
        "DialogComp" : DialogCompetencia,
        "DataAreaFormacion" : DataAreaFormacion,
        "DialogFuent" :  DialogFuente,
        "SectionCard" : SectionCardGroup,
        "EmpleadoDataTable" : EmpleadoDataTable,
        "PlanBloque" : PlanBloque,
        "RevisionData" : RevisionData,
        AngleDoubleIco : AngleDouble,
        ClockIco : Clock ,
        PixelIco : Pixel,
        AlarmClockIco : AlarmClock,
        TimerIco : Timer,
        DoubleCheckIco :  DoubleCheck,
        GroupIco : Group,
        SelectedFileIco : SelectedFile,
        ComponentIco : Component,
        EditPlan,
        RevisionAside
        
    },

}

</script>
